const frontendTranslationsES = {
  "seo.subpage.main.title": "Reserva de mesas en línea - MojStolik.pl",
  "seo.subpage.main.description":
    "Reserva mesa online en los restaurantes más populares de Polonia. Descarga la aplicación, elige dónde quieres pedir mesa y reserva. Con MojStolik.pl es ultrasencillo.",
  "seo.subpage.forRestaurant.title": "Plataforma de reservas de restaurantes - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl es una plataforma de gestión de reservas de restaurantes. Todas las reservas en un solo lugar. Una plataforma adicional para la comunicación con los clientes del restaurante.",
  "seo.subpage.aboutUs.title": "Una aplicación muy sencilla para los que les gusta comer fuera.",
  "seo.subpage.aboutUs.description":
    "Cientos de los restaurantes más populares y respetados del país y miles de usuarios que utilizan la plataforma MojStolik.co.uk. Deja que una mesa te espere a ti y nunca al revés.",
  "seo.subpage.howItWork.title":
    "Descarga la aplicación MojStolik.co.uk en tu teléfono y reserva mesas",
  "seo.subpage.howItWork.description":
    "Con unos pocos clics, tienes una mesa en un restaurante esperándote. Tú decides cuándo, dónde y con quién quieres comer o cenar. Descárgate la app y reserva tus mesas.",
  "seo.subpage.documents.title": "Descubra las ventajas de trabajar con MojStolik.pl",
  "seo.subpage.documents.description":
    "¿Te preguntas si merece la pena? Consulte lo más destacado en los documentos de MojStolik.es. Contratos, ofertas de marketing y ventajas de la cooperación.",
  "seo.subpage.privacyPolicy.title":
    "MojStolik.pl como responsable del tratamiento de los datos personales",
  "seo.subpage.privacyPolicy.description":
    "Lea la información esencial y conozca mejor la plataforma MojStolik.pl",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - Descubre los restaurantes de tu zona",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.es utiliza cookies. Descubre con nosotros los restaurantes más apreciados de tu zona.",
  "seo.subpage.registration.title": "Regístrate y utiliza la aplicación hoy mismo. MojStolik.pl",
  "seo.subpage.registration.description":
    "Reservar una mesa nunca ha sido tan fácil. Pagos cómodos, a cualquier hora y con cualquier número de personas. Regístrese y utilice MojStolik.pl hoy mismo.",
  "seo.subpage.login.title": "Entra en la aplicación y elige restaurantes de MojStolik.es",
  "seo.subpage.login.description":
    "En nuestra aplicación encontrarás los restaurantes más populares de toda Polonia. Descárgate la aplicación, inicia sesión y utiliza el sencillo sistema de reserva de mesas.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - Restaurante responsable",
  "seo.subpage.covid.restaurant.description":
    "Soluciones para restaurantes que le ayudarán a mantener su negocio en estos tiempos difíciles.",
  "seo.subpage.covid.client.title": "COVID-19 - Visitantes responsables",
  "seo.subpage.covid.client.description": "Guía para restauradores responsables",
  "forRestaurantsPage.subheader":
    "Cuando los clientes ven su restaurante en este grupo, saben que pueden esperar un servicio de primera calidad.",
  "forRestaurantsPage.platform": "MOJSTOLIK.PL ES",
  "forRestaurantsPage.1.strong": "Es el fin de la era del libro y el lápiz en tu restaurante.",
  "forRestaurantsPage.2.strong":
    "es una respuesta innovadora a las necesidades de una sociedad cada vez más impaciente y digital,",
  "forRestaurantsPage.2.text":
    "Disfrute de las funciones avanzadas de la aplicación para tabletas y de la interfaz del navegador. Olvídate del libro de reservas y apuesta por una solución única para cada tipo de restaurante.",
  "forRestaurantsPage.3.strong": "es la gestión inteligente de todas sus reservas,",
  "forRestaurantsPage.3.text":
    "Con el sistema MOJSTOLIK.PL, minimizará el riesgo de mesas vacías donde deberían estar sentados los invitados gracias a:",
  "forRestaurantsPage.4.strong": "es una recopilación de información sobre sus visitantes,",
  "forRestaurantsPage.4.text":
    "que le permite estar en contacto permanente con ellos y sorprenderles con su atención a sus necesidades y hábitos,",
  "forRestaurantsPage.5.strong": "es su base de datos profesional,",
  "forRestaurantsPage.5.text": " y garantizamos su seguridad y el cumplimiento de RODO,",
  "forRestaurantsPage.6.strong":
    "es una herramienta moderna para ponerse en contacto con sus visitantes,",
  "forRestaurantsPage.6.text":
    ' confirmando la visita y recordando a la gente incluso durante el "encierro",',
  "forRestaurantsPage.7.strong": "es la manifestación de su responsabilidad durante COVID-19 -",
  "forRestaurantsPage.7.text": " tiene contacto con los visitantes y gestiona las restricciones,",
  "forRestaurantsPage.8.strong": "es organizar y racionalizar el trabajo del equipo,",
  "forRestaurantsPage.9.strong": "es la posibilidad de reservar mesa automáticamente por teléfono",
  "forRestaurantsPage.9.text":
    ' en el restaurante a cualquier hora del día o de la noche, sin implicar al equipo. Con el servicio IRV, "nadie se irá con las manos vacías" y nadie tendrá que esperar una respuesta,',
  "forRestaurantsPage.10.strong":
    "es un sistema desarrollado en colaboración con algunos de los restaurantes más frecuentados de Polonia.",
  "forRestaurantsPage.10.text": " Escuchamos y satisfacemos sus necesidades,",
  "forRestaurantsPage.11.strong":
    "es un servicio de asistencia permanente en Polonia y apoyo diario en marketing y relaciones públicas,",
  "forRestaurantsPage.12.strong": "es la formación tecnológica,",
  "forRestaurantsPage.13.strong":
    "es la empresa de los restauradores más concienciados de Polonia,",
  "forRestaurantsPage.13.text": "que sepan gestionar su empresa con eficacia,",
  "forRestaurantsPage.14.text": "es mucho más...",
  "forRestaurantsPage.form.title":
    "Si está indeciso y desea obtener más información sobre la plataforma MojStolik.pl, rellene este breve formulario y nos pondremos en contacto con usted:",
  "covid.restaurant.1.strong": "PLAN B:.",
  "covid.restaurant.1.text":
    "Prepare ya SU plan de contingencia en caso de que aumente el número de enfermedades: piense qué maniobras y alternativas tiene en caso de reintroducción de diversos grados de restricciones, cómo reorganizará su trabajo, qué procedimientos introducirá, cómo mantendrá el contacto con los huéspedes, gestionará sus recursos financieros y planificará el trabajo de su personal. La experiencia de la primavera le enriquecerá. No se deje sorprender por segunda vez.",
  "covid.restaurant.2.strong": "La SEGURIDAD Y LA HIGIENE ya son norma, pero..:",
  "covid.restaurant.2.text":
    "No dejes pasar el tema. Compruebe que todos los procedimientos de seguridad recomendados para los clientes y el personal funcionan a la perfección: mascarillas en las caras de los camareros, desinfección de las mesas después de cada cliente, menús electrónicos como opción para los clientes, procedimientos de lavado y desinfección de manos, mantenimiento de una distancia adecuada entre las mesas y facilitar al máximo los pagos sin efectivo. No tenga miedo de instruir educadamente a los clientes sobre cómo comportarse en su restaurante.",
  "covid.restaurant.3.strong": "CONSTRUYE TU COMUNIDAD:",
  "covid.restaurant.3.text":
    "Nuestras observaciones muestran que aquellos restaurantes que consiguieron crear una comunidad a su alrededor y, durante el cierre, se mantuvieron en contacto con sus clientes a través de las redes sociales o de mensajes de texto enviados desde la aplicación MojStolik.pl se desenvolvieron mucho mejor en la nueva realidad que aquellos que no se comunicaron adecuadamente. Así que asegúrese de que ya tiene un contacto constante, interesante y fiable con sus huéspedes: ocúpese de las redes sociales, de su sitio web y piense de qué otra forma podrá comunicarse cuando sea necesario. Afortunadamente, el Gobierno retiró en mayo la obligación de que todos los restaurantes tuvieran una base de datos de sus clientes, pero como restaurador responsable deberías crearla. En este sentido, es muy útil utilizar la plataforma MojStolik.pl, que facilita al máximo la recopilación y el almacenamiento de los datos (nombre, correo electrónico, teléfono) de sus clientes de acuerdo con RODO. Los datos pertenecen al restaurante.",
  "covid.restaurant.4.strong": "¿ES NECESARIO RESERVAR?",
  "covid.restaurant.4.text":
    "En muchos países de Europa y del mundo se ha instalado la idea de que sólo se puede ir a un restaurante si se ha reservado mesa con antelación. Así que prepárate para esta eventualidad y cuida la tecnología.",
  "covid.restaurant.5.strong": "INTENTE CONCIENCIAR A SUS INVITADOS DE -",
  "covid.restaurant.5.text":
    "A través de las redes sociales, pero también de otros canales, comunique por qué es importante seguir las normas de seguridad de los restaurantes y qué procedimientos ha puesto en marcha en sus instalaciones para minimizar el riesgo de infección. Hazles saber también que algunos de sus comportamientos te exponen a pérdidas que no puedes permitirte en estos momentos difíciles. Se trata sobre todo de reservas no utilizadas, con las que pierdes tiempo y dinero. Algunas personas simplemente no se dan cuenta del gran problema que esto supone para los restauradores. Con la plataforma Mojstolik.es, el proceso de cancelación de una reserva es muy sencillo para los clientes y completamente automático para el restaurante.",
  "covid.restaurant.6.strong": "BUSCAR EL AHORRO:",
  "covid.restaurant.6.text":
    "Especialmente hoy en día, cada comensal es un tesoro y no hay que dejar que espere a que un camarero ocupado recoja su reserva, que con un personal limitado tiene muchas más responsabilidades y puede que no tenga tiempo de correr al teléfono. Nuestros cálculos muestran que el sistema de reservas ahorra más de 20 horas de trabajo de camareros o anfitriones al mes, ya que alrededor del 50% de las reservas pasan automáticamente del teléfono a Internet y se suman a la carga de trabajo de su equipo. ¡Puro ahorro!",
  "covid.restaurant.7.strong": "OPTIMIZAR EL USO DE LAS SALAS:",
  "covid.restaurant.7.text":
    "Con una ocupación reducida en los restaurantes, optimizar la gestión de las reservas y la utilización de las mesas es crucial para el negocio. Eche un vistazo a las soluciones tecnológicas llave en mano que le facilitarán el control de las reservas procedentes de todos los canales de comunicación: sitio web, aplicación, redes sociales, teléfono, sin cita previa. La plataforma MojStolik.pl es una solución de este tipo.",
  "covid.restaurant.8.strong": "MINIMIZANDO EL EFECTO NO ESPECTÁCULO:",
  "covid.restaurant.8.text":
    "Una reserva que no se cancela pero el cliente no se presenta es la perdición de los restauradores. En una época en la que sólo se permite una ocupación del 55-75%, este comportamiento de los clientes tiene consecuencias financieras reales. Por lo tanto, hay que intentar minimizar el riesgo de que esto ocurra. MojStolik.pl envía a los clientes recordatorios por SMS sobre su reserva. La cancelación es simplemente cuestión de hacer clic en un enlace. Utilizando esta solución, aumentará la posibilidad de explotar plenamente el potencial de su restaurante y evitará pérdidas. En Londres, los hosteleros se han rebelado porque no es el momento de perder la oportunidad de ganar dinero.",
  "covid.restaurant.9.strong": "MENÚ SEGURO:",
  "covid.restaurant.9.text":
    "Se recomienda que los menús de los restaurantes sean desechables y se tiren cada vez que lleguen a las manos de alguien. Este tipo de restricciones se han introducido como obligación, por ejemplo, en algunos estados de Estados Unidos. Esto no es ni económico ni ecológico. Así que, ¿por qué no considerar la opción de presentar el menú en el smartphone del comensal? Los usuarios de MojStolik.pl ya disponen de esta opción. El camarero tiene la posibilidad de proponer un menú a los clientes que se hayan descargado la aplicación y se hayan familiarizado con ella. También es posible enviar un SMS con un enlace antes de la visita desde la app y pedir a los comensales que ya se metan en el ambiente de la velada eligiendo los platos.☺ La app también se puede descargar en la mesa mediante un código QR. Una opción rápida, moderna e imaginativa, para algunos comensales. Merece la pena ofrecerla.",
  "covid.restaurant.10.strong": "ADAPTAR LA COCINA:",
  "covid.restaurant.10.text":
    "El menú debe ser bastante corto y basarse principalmente en productos de temporada, locales o simplemente disponibles. Otras opciones son un riesgo, a menos que te estés preparando para un menú complementario, fácilmente sustituible. Si hay problemas y nuevas restricciones, como viajes de larga distancia o fronteras cerradas, no tendrás problemas con los suministros y la obtención de productos para el chef. Reorganizar la cocina de un día para otro será mucho más fácil. Los costes tampoco son insignificantes.",
  "covid.restaurant.11.strong": "DESCARGAR PROVEEDORES RESPONSABLES:",
  "covid.restaurant.11.text":
    "Busque socios en los negocios. Empresas que le ayuden, comprendan y aporten nuevas soluciones. Busque proveedores de productos de alta calidad cerca de su negocio: depender de suministros a larga distancia y productos difíciles de encontrar es demasiado arriesgado hoy en día. Puede informarse de lo que hacen las empresas por sus restaurantes/socios en www.fortalks.pl.",
  "covid.restaurant.header": "MANUAL PARA UN RESTAURANTE RESPONSABLE",
  "covid.restaurant.title": "MANUAL PARA UN RESTAURANTE RESPONSABLE",
  "covid.restaurant.text1":
    "Todos sabemos que en Polonia los restaurantes luchan por sobrevivir, por lo que en su mayoría tienen un alto nivel de trabajo, higiene y comunicación con los clientes. Vayamos un paso más allá. La plataforma MojStolik.pl y el equipo de FOR Solutions han desarrollado un sistema y un paquete de soluciones preparadas en caso de nuevas restricciones y una posible segunda oleada de la pandemia. El objetivo de la guía es ayudar a los restaurantes a sobrevivir en el negocio y también reducir al máximo el peligro de infección por coronavirus.",
  "covid.restaurant.text2":
    "La primera oleada del coronavirus y el bloqueo que le siguió supusieron un auténtico shock para el mercado de la restauración. Todo el mundo tardó algún tiempo en reponerse y pasar a operar en la nueva realidad. Ahora estamos acostumbrados a la situación, más ricos en conocimientos y experiencia sobre cómo afrontar situaciones de crisis. Sin embargo, en su propio interés y en el de sus clientes, los restaurantes deben hacer todo lo posible para evitar la propagación del virus. Hemos hecho balance de cómo preparar su restaurante ante el creciente número de infecciones:",
  "covid.restaurant.welcome1": "Drogi Restauratorze",
  "covid.restaurant.welcome2":
    "He aquí 11 soluciones que le ayudarán a mantener su negocio en marcha en estos tiempos difíciles.",
  "covid.restaurant.summary":
    "Esperamos que esta recopilación de consejos le resulte útil para adaptar su restaurante a la nueva realidad y prepararse para cualquier dificultad. Si tiene alguna pregunta adicional, estamos a su disposición. Si desea más información sobre cómo trabajar con nosotros, rellene el formulario. Le llamaremos o le escribiremos.",
  "covid.restaurant.greetings": "Queremos ayudar, ¡por eso unimos nuestras fuerzas!",
  "covid.restaurant.team": "El equipo de MojStolik.es y FOR Solutions.",
  "covid.client.page.title": "GUÍA DEL VISITANTE RESPONSABLE",
  "covid.client.dear.guest": "Estimado Huésped",
  "covid.client.description":
    " Ningún restaurante puede existir sin ti. Todo es por ti. Si no apoyas el negocio gastronómico, desaparecerá de las calles de nuestras ciudades. Y admítelo, un mundo sin bares y restaurantes sería terriblemente triste y vacío, porque las reuniones en torno a la mesa unen, entretienen y desarrollan nuestra vida social.",
  "covid.client.description2":
    "Así que hacemos un llamamiento: apoye a sus restaurantes favoritos, visítelos, pero hágalo con cabeza. En la era del coronavirus, nuestra responsabilidad social colectiva es increíblemente importante. Tome nota también de algunos de sus comportamientos que causan verdaderos perjuicios a los restaurantes. Damos ejemplos de ellos para ayudar a los restaurantes a funcionar de forma más eficiente. Y, por último, conoce todas las normas de seguridad para contribuir a mantener el alto nivel de trabajo, higiene y organización de los restaurantes. ¡Ellos se ocupan! ¡Cuídate tú también!",
  "covid.client.description3":
    "Entonces, ¿cómo disfrutar responsablemente de su tiempo en sus restaurantes favoritos? MojStolik.co.uk y FOR Solutions han preparado algunos consejos para ti:",
  "covid.client.summary":
    "Siguiendo estas sencillas normas garantizarás tu seguridad y la del personal de tu restaurante favorito, y estando allí y disfrutando de sus deliciosos platos, les estarás apoyando de forma realista y ayudándoles a superar un momento difícil. La imagen de nuestras ciudades sin restaurantes sería trágica....",
  "covid.client.1.strong": "PRINCIPIOS BÁSICOS DE HIGIENE -",
  "covid.client.1.text":
    "Es decir, cuando entres en el establecimiento, ponte la mascarilla y quítatela, sólo cuando te sientes a la mesa, desinféctate las manos antes de entrar (los restaurantes están obligados a tener un dispensador de líquidos a la entrada). Al ir al baño, cúbrase de nuevo la boca y la nariz con una mascarilla. Estos puntos son realmente clave para reducir el riesgo de contraer COVID-19.",
  "covid.client.2.strong": "DISTANCIA SOCIAL -",
  "covid.client.2.text":
    "Sabemos que mantener la distancia adecuada es incómodo y que a menudo nos olvidamos de nosotros mismos, pero basta con unas sencillas normas: no estorbar al equipo y no cambiar la posición de las mesas sin permiso del camarero o el encargado. Se han establecido para garantizar tu seguridad.",
  "covid.client.3.strong": "ELECCIÓN DE LA MESA -",
  "covid.client.3.text":
    "Cuando entres en un restaurante (¡con la cara tapada!), espera a que el camarero te indique la mesa correcta. Esto le facilitará el trabajo y le ayudará a sentar a sus invitados de forma segura. Si puede elegir usted mismo el asiento, siéntese sólo en las mesas señalizadas como desinfectadas.",
  "covid.client.4.strong": "RESERVA -",
  "covid.client.4.text":
    "Si sabes que vas a ir a un restaurante esta noche, por ejemplo, reserva mesa con antelación. Esto facilita al personal del restaurante la planificación de los asientos de sus comensales, pero también el aprovisionamiento y el trabajo del personal. Además, reduce al máximo el tiempo de contacto con el personal, lo que le beneficia tanto a usted como a ellos. Con la aplicación MojStolik.es podrá reservar mesa en el restaurante de su elección de forma fácil y rápida. ¿Por qué esperar a una mesa cuando ella puede esperarte a ti?",
  "covid.client.5.strong": "¡ANULE SU RESERVA SI NO PUEDE VENIR!",
  "covid.client.5.text":
    "No presentarse en un restaurante a pesar de haber hecho una reserva no sólo es de mala educación, por decirlo suavemente, sino que además expone al restaurador a pérdidas reales. No puede asignar tu mesa a nadie porque, al fin y al cabo, está reservada. Te ha tenido en cuenta a ti y a tus compañeros a la hora de planificar el avituallamiento. Una situación así supone una pérdida económica real para él, ahora que la ocupación del restaurante es reducida de todos modos, esto es especialmente grave. La plataforma MojStolik.pl también te lo pondrá más fácil: no sólo te recordará por SMS a qué hora y dónde te espera el chef, sino que también te facilitará al máximo la cancelación de la reserva en caso necesario. A un solo clic.",
  "covid.client.6.strong": "MENÚ TÁCTIL -",
  "covid.client.6.text":
    "si tienes una app de reserva de mesa en tu smartphone, también te permitirá ver el menú sin tener que coger el del restaurante en la mano. Si no tienes la app, descárgatela utilizando los códigos QR especiales que tienen disponibles en las mesas.",
  "covid.client.7.strong": "PAGAR SIN CONTACTO -",
  "covid.client.7.text":
    "evite el contacto directo y los pagos en efectivo. Pague con tarjeta de crédito, blíster o teléfono.",
  "covid.client.8.strong": "NO CRITIQUES -",
  "covid.client.8.text":
    "siguen siendo tiempos muy difíciles para un sector que lucha por sobrevivir prácticamente a diario. Así que absténgase de ser crítico en público y céntrese en cambio en las emociones positivas de la fiesta. Las buenas emociones nos hacen mucha falta a todos en estos momentos, especialmente a los restaurantes que se esfuerzan doblemente por sobrevivir.",
  "covid.client.9.strong": "APOYO -.",
  "covid.client.9.text":
    "si has quedado satisfecho con tu visita, no dejes de contarlo en tus redes sociales, haz INSTA STORIES y diviértete elogiando los platos y atrayendo a otros comensales a tu restaurante favorito.",
  "forRestaurantsPage.global.perMonth": "PLN/mes",
  "forRestaurantsPage.global.perMonth.short": "PLN/mes",
  "forRestaurantsPage.global.button.choose": "ELEGIR",
  "forRestaurantsPage.global.button.next": "SIGUIENTE",
  "forRestaurantsPage.global.button.back": "DEVOLVER",
  "forRestaurantsPage.global.button.accept": "ACEPTAR",
  "forRestaurantsPage.global.button.discover": "VER PLANES DISPONIBLES",
  "forRestaurantsPage.global.button.send": "ENVIAR",
  "forRestaurantsPage.global.button.learnMore": "Seguir leyendo",
  "forRestaurantsPage.apiError": "Problemas técnicos. Vuelva a intentarlo más tarde.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.ES ES MÁS QUE UN SISTEMA",
  "forRestaurantsPage.1.subsection.1.header": "PORTAL Y APLICACIÓN PARA VISITANTES",
  "forRestaurantsPage.1.subsection.1.text":
    "Una plataforma y app sencilla y cómoda que permite reservar mesa en tu restaurante al instante.",
  "forRestaurantsPage.1.subsection.2.header": "SISTEMA TELEFÓNICO (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "El sistema telefónico (IVR) atenderá las reservas en lugar de que su equipo pueda dedicarse a atender a sus clientes en el restaurante.",
  "forRestaurantsPage.1.subsection.3.header": "PORTAL Y APLICACIÓN PARA RESTAURANTES",
  "forRestaurantsPage.1.subsection.3.text":
    "Gestione las reservas y las mesas de forma automática o manual desde la aplicación o el portal del restaurante en la tableta.",
  "forRestaurantsPage.2.header": "GESTIONAR DESDE LA APLICACIÓN O EL NAVEGADOR",
  "forRestaurantsPage.3.header": "OLVIDAR EL NO-SHOW",
  "forRestaurantsPage.3.listItem.1": "Confirmación por SMS y recordatorios de reserva",
  "forRestaurantsPage.3.listItem.2": "Opciones de cancelación rápidas y sencillas",
  "forRestaurantsPage.3.listItem.3": "Análisis de la actividad histórica de los visitantes",
  "forRestaurantsPage.4.header": "DIRIGIR EFICAZMENTE EL TRÁFICO AL RESTAURANTE",
  "forRestaurantsPage.5.header": "¿QUÉ DICEN LOS RESTAURADORES DE NOSOTROS?",
  "forRestaurantsPage.5.opinion.1.title":
    "Mariusz Diakowski, propietario del restaurante Old House:",
  "forRestaurantsPage.5.opinion.1.text":
    'Trabajar con MojStolik.co.uk ha supuesto una auténtica revolución para Stary Dom. Ni yo ni el equipo del restaurante nos imaginamos volver a la época del libro y el lápiz. De hecho, MojStolik.pl hizo pruebas de su plataforma en nuestro restaurante. Teníamos algunas dudas, pero decidimos arriesgarnos. No nos arrepentimos en absoluto. Todos nuestros comentarios y sugerencias se implementaron de inmediato. Gracias a las pruebas de "organismo vivo", este sistema responde realmente a las necesidades de los restaurantes con alta ocupación, facilita increíblemente el trabajo y acorta el tiempo. Se acabaron los bolígrafos, los lápices, los correctores pegando páginas, la letra ilegible y los libros de contabilidad poco prácticos. Ahora todo está organizado y es fácil de usar. También añadiría que la plataforma es prácticamente impecable y, en caso necesario, el servicio de asistencia funciona a la perfección.',
  "forRestaurantsPage.5.opinion.2.title":
    "Marek Katrycz, Director de Operaciones de STIXX Bar&Grill:",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl es un sistema muy moderno e innovador, gracias al cual nosotros, como gran restaurante, podemos gestionar eficazmente las reservas y la distribución de los asientos de nuestros clientes, lo que simplifica enormemente el trabajo de nuestro equipo. Definitivamente recomendamos este sistema, estamos constantemente mejorando su funcionalidad, es intuitivo y muy fácil de usar, ha reducido el tiempo que nos lleva tomar reservas y encontrarlas, lo que es especialmente útil en caso de alta ocupación.",
  "forRestaurantsPage.5.opinion.3.title": "Robert Goluba, Director de Marca de Bombaj Masala:",
  "forRestaurantsPage.5.opinion.3.text":
    "¿Por qué decidimos utilizar el sistema MojStolik.es en nuestros restaurantes? Buena acogida en el sector, además de un enfoque realmente agradable y profesional para un nuevo cliente. El sistema es muy intuitivo, pero llevó mucho tiempo formar al personal hasta que todos y cada uno de los empleados del restaurante supieran utilizar el panel, así que no hubo problemas para implantar My Table en el restaurante. El primer día con muchos clientes y reservas en nuestro restaurante fue suficiente para convencernos de las ventajas de las soluciones del sistema MojStolik.pl. Estoy seguro de que nos ha permitido (y nos sigue permitiendo) recibir a los clientes con mayor fluidez y, en consecuencia, atender a más clientes. Esto se traduce naturalmente en el balance final. Si, al cabo de unas semanas, alguien me hubiera ordenado volver a trabajar con un libro de reservas tradicional, me habría sentido como... en el siglo XX.",
  "forRestaurantsPage.6.header": "CONOZCA LOS PLANES DE MOJSTOLIK.PL",
  "forRestaurantsPage.6.subheader":
    "Elija un plan para su restaurante y empiece a trabajar con nosotros.",
  "forRestaurantsPage.discountCodeInput": "Introduzca el código promocional",
  "forRestaurantsPage.discountCodeButton": "Utilice",
  "forRestaurantsPage.discountCodeLoading": "Carga en curso...",
  "forRestaurantsPage.discountCodeSuccess": "Código promocional cargado",
  "error.promotion.not.exists": "El código promocional especificado no existe",
  "forRestaurantsPage.allOffers": "Para una comparación detallada de los planes de MOJSTOLIK.PL",
  "forRestaurantsPage.allOffers.orange": "Ir aquí",
  "forRestaurantsPage.offers.offerItem.1.header": "LUZ",
  "forRestaurantsPage.offers.offerItem.1.text":
    "Para restaurantes que valoran la sencillez de automatizar los procesos de cobro de reservas en tiempo real.",
  "forRestaurantsPage.offers.offerItem.2.header": "AVANZADO",
  "forRestaurantsPage.offers.offerItem.2.text":
    "Para restaurantes que desean gestionar salas y mesas además de las propias reservas.",
  "forRestaurantsPage.offers.offerItem.3.header": "AVANZADO",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "Para grandes restaurantes, donde es esencial la automatización total de todos los canales de comunicación con los clientes.",
  "forRestaurantsPage.offers.subscription": "Abono",
  "forRestaurantsPage.offers.commission": "Comisión",
  "forRestaurantsPage.offers.commission.currency": "zł",
  "forRestaurantsPage.offers.header": "Opciones adicionales",
  "forRestaurantsPage.offers.additional-options.prepayments": "Módulo de prepago para reservas",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Servicio automático de reservas telefónicas (IVR) con 300 SMS/mes adicionales",
  "forRestaurantsPage.offers.additional-options.smsPackage": "Paquete SMS adicional - 1000 pcs/mes",
  "forRestaurantsPage.offers.mostPopular": "La opción más común",
  "forRestaurantsPage.offers.pricingSetting1":
    "Los precios anteriores se aplican a un periodo de facturación anual. Cambiar a facturación mensual:",
  "forRestaurantsPage.offers.pricingSetting2":
    "Los precios anteriores se aplican a un periodo de facturación mensual. Cambie a la facturación anual:",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1 Todos los precios de la oferta son precios netos en PLN y se añadirá el IVA legal al tipo aplicable el día de la facturación.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2 En el caso del modelo de pago anual, se presenta la cuota mensual, mientras que el pago es único por 12 meses por adelantado.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3 El descuento de marketing se concede por un importe de 50 PLN netos de la cuota mensual, siempre que el restaurante comparta el enlace de reserva a través de MOJSTOLIK.PL en las Redes Sociales del restaurante al menos 2 veces al mes.",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4 El coste de un SMS fuera del abono es de 0,09 PLN netos.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5 Cuando se utiliza el módulo de prepago, el operador de pago cobra una comisión del 1,59% del importe de la transacción en cada operación.",
  "forRestaurantsPageAllOffers.header": "Explorar los planes de MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Elija un plan para su restaurante y empiece a trabajar con nosotros.",
  "forRestaurantsPageAllOffers.offer.1": "LUZ",
  "forRestaurantsPageAllOffers.offer.2": "AVANZADO",
  "forRestaurantsPageAllOffers.offer.3": "AVANZADO",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Canales de reserva a disposición del cliente",
  "forRestaurantsPageAllOffers.listHeader.2": "Gestión de reservas de restaurantes",
  "forRestaurantsPageAllOffers.listHeader.3": "Configuración del perfil del restaurante",
  "forRestaurantsPageAllOffers.listHeader.4":
    "Accesibilidad de los restaurantes desde las instalaciones",
  "forRestaurantsPageAllOffers.listHeader.5": "Servicios adicionales",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1": "Reservas desde www.mojstolik.pl",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Reservas desde la aplicación móvil MojStolik",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3": "Reservas en el sitio web del restaurante",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Reservas con Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Reservas desde Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Confirmaciones/recordatorios de reserva por SMS/notificación de solicitud**.",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
    "Gestión automática de reservas telefónicas",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
    "Reservas desde el Instagram del restaurante",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Reservas desde un buzón de correo electrónico (autorespondedor)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Enlaces / códigos QR en artículos y material de relaciones públicas de restaurantes",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Lista de reservas - vista tabular",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Reservas en el plano del pabellón",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Vista Gantt (gráfico de reservas)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4": "Detalles de la reserva y edición",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Gestión avanzada de reservas",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Informe diario de reservas",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7":
    "Gestión del pago por adelantado de las reservas",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Servicio sin cita previa",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Historial de reservas / análisis de las preferencias y el comportamiento de los huéspedes",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": 'Minimizar el efecto "no-show',
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Funcionamiento en directo",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "Base de datos de clientes de restaurantes / cumplimiento de RODO",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "SEGURIDAD DE LOS DATOS / Sistema encriptado y distintos niveles de acceso en función de las funciones",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Tarjeta de visita y perfil individual del restaurante en el portal del cliente y la aplicación móvil",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Datos descriptivos del restaurante",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Material gráfico",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Gestión de las horas de disponibilidad",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Vacaciones y días especiales",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6":
    "Parametrización de la aceptación de reservas",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Configuración de la sala y las mesas",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Portal de gestión para restaurantes",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Aplicación de restaurante para tablet",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1": "Servicio de asistencia y soporte técnico",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
    "Formación para el personal de los restaurantes",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Presencia / promoción de los restaurantes en los canales de medios sociales MojStolik.pl",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Número de mensajes SMS disponibles en la suscripción",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/mes",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/mes",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/mes",
  "forRestaurantsPageAllOffers.listItem.optional": "Opción",
  "forRestaurantsPageForm.downloadButton": "DESCARGAR LOS DATOS",
  "forRestaurantsPageForm.header": "Aquí es donde empiezas a trabajar con mojstolik.es",
  "forRestaurantPageForm.apiError":
    "Error al descargar los datos de la oferta. Vuelva a intentarlo más tarde y le pedimos disculpas por las molestias.",
  "forRestaurantPageForm.paramsError":
    "Lo sentimos, no se han podido mostrar los detalles de la oferta. Para ver nuestras ofertas vaya a",
  "forRestaurantPageForm.paramsError.link": "páginas de ofertas",
  "forRestaurantsPageForm.chosenPlan": "Plan seleccionado MOJSTOLIK.PL",
  "forRestaurantsPageForm.discountCode": "Promoción:",
  "forRestaurantsPageForm.payment.yearly": "El plan seleccionado se factura anualmente.",
  "forRestaurantsPageForm.payment.monthly": "El plan seleccionado se factura mensualmente.",
  "forRestaurantsPageForm.additionalOptions": "Opciones adicionales seleccionadas:",
  "forRestaurantsPageForm.formHeader.1": "Facilite los datos básicos del restaurante",
  "forRestaurantsPageForm.formHeader.2": "Introduzca los datos de la persona de contacto",
  "forRestaurantsPageForm.formHeader.3": "Proporcione detalles para el Acuerdo",
  "forRestaurantsPageForm.formHeader.4":
    "Introduzca los datos de la persona que representa a la entidad",
  "forRestaurantsPageForm.formHeader.5": "Acuerdo MOJSTOLIK.PL",
  "forRestaurantsPageForm.restaurantName": "Nombre del restaurante",
  "forRestaurantsPageForm.street": "Calle",
  "forRestaurantsPageForm.apartment": "Local",
  "forRestaurantsPageForm.postalCode": "Código postal",
  "forRestaurantsPageForm.city": "Ciudad",
  "forRestaurantsPageForm.email": "Correo electrónico",
  "forRestaurantsPageForm.phoneNumber": "Número de teléfono",
  "forRestaurantsPageForm.website": "Dirección web",
  "forRestaurantsPageForm.website.menu": "Dirección web con el menú del restaurante",
  "forRestaurantsPageForm.name": "Nombre",
  "forRestaurantsPageForm.surname": "Nombre",
  "forRestaurantsPageForm.title": "Título",
  "forRestaurantsPageForm.entity": "Nombre de la entidad",
  "forRestaurantsPageForm.email.invoice": "Dirección de correo electrónico para la factura",
  "forRestaurantsPageForm.email.contact": "Correo electrónico de contacto",
  "forRestaurantsPageForm.proxy": "Tipo de poder",
  "forRestaurantsPageForm.select.owner": "Propietario",
  "forRestaurantsPageForm.select.manager": "Director",
  "forRestaurantsPageForm.select.boardMember": "Miembro del Consejo de Administración",
  "forRestaurantsPageForm.select.other": "Otros",
  "forRestaurantsPageForm.contract": "Contenido del acuerdo",
  "forRestaurantsPageForm.pdfLink": "Descargar pdf",
  "forRestaurantsPageForm.statement.1":
    "Consiento el tratamiento de mis datos personales por Table4Rest sp. z o.o. con el fin de celebrar un contrato de prestación de servicios para el suministro y uso de la aplicación MojStolik.pl.",
  "forRestaurantsPageForm.statement.2":
    "Acepto los términos y condiciones del acuerdo anterior y las normas y reglamentos de MOJSTOLIK.PL y declaro que estoy autorizado a celebrar un contrato de prestación de servicios para el suministro y uso de la aplicación MojStolik.pl en nombre del restaurante.",
  "forRestaurantsPageForm.loading": "Su formulario se envía...",
  "forRestaurantsPageForm.success": "Formulario enviado correctamente",
  "forRestaurantsPageForm.nipError": "Introduzca el número de IVA correcto",
  "forRestaurantsPageForm.loadingData": "Descarga de datos en curso...",
  "forRestaurantsPageForm.error": "Se ha producido un error.",
  "forRestaurantsPageForm.dataSuccess": "Datos descargados correctamente",
  "forRestaurantsPageForm.generatingContract": "Generación de contratos en curso...",
  "forRestaurantsPageSuccess.header": "Gracias por elegir Mojstolik.pl",
  "forRestaurantsPageSuccess.text.1":
    "EN UNOS INSTANTES, SE LE ENVIARÁ UN DOCUMENTO CONTRACTUAL A LA DIRECCIÓN DE CORREO ELECTRÓNICO QUE ACEPTÓ EN EL PASO ANTERIOR.",
  "forRestaurantsPageSuccess.text.2":
    "Igualmente le prepararemos y facilitaremos las instrucciones necesarias para que pueda configurar su restaurante en el sistema y empezar a trabajar juntos.",
  "forRestaurantsPageSuccess.text.3": "¡nos vemos allí!",
  "forRestaurantsPageSuccess.button": "Volver a la portada",
  "global.messages.sessionTimeout": "Sesión expirada",
  "global.form.message.requiredField": "Este campo es obligatorio",
  "global.form.message.vatIDNumber": "El NIF debe contener 10 dígitos",
  "global.form.message.invalidEmailFormat":
    "Formato incorrecto de la dirección de correo electrónico",
  "global.form.message.invalidPhoneFormat": "Formato incorrecto del número de teléfono",
  "global.form.message.invalidBirthDateFormat": "Formato incorrecto de la fecha de nacimiento",
  "global.form.message.invalidPostCodeFormat":
    "Formato incorrecto del código postal. Esperado XX-XXX",
  "global.form.message.invalidNipFormat": "Formato incorrecto del número de IVA",
  "global.form.message.invalidKrsFormat": "Formato incorrecto del número KRS",
  "global.form.message.invalidRegonFormat": "Formato incorrecto del número REGON",
  "global.form.message.invalidUrlFormat": "Formato incorrecto de la dirección web",
  "global.form.message.newPasswordsDoNotMatch": "Los nuevos eslóganes son diferentes.",
  "global.form.message.atLeastNineCharacters": "Se requieren al menos nueve caracteres",
  "global.time.minutes": "minut",
  "aboutPage.metadata.title": "O en",
  "aboutPage.metadata.description": "Las opciones",
  "aboutPage.metadata.keywords": "sobre nosotros palabras clave",
  "accountPage.metadata.title": "Su cuenta",
  "accountPage.metadata.description": "Descripción de su cuenta",
  "accountPage.metadata.keywords": "Palabras clave de su cuenta",
  "contactPage.metadata.title": "Póngase en contacto con nosotros",
  "contactPage.metadata.description": "Contactar con opis",
  "contactPage.metadata.keywords": "Palabras clave de contacto",
  "documentsPage.metadata.title": "Documentos",
  "documentsPage.metadata.description": "Descripción de los documentos",
  "documentsPage.metadata.keywords": "Palabras clave",
  "forGuestPage.metadata.title": "Para el huésped",
  "forGuestPage.metadata.description": "Para el visitante, una descripción de",
  "forGuestPage.metadata.keywords": "Para el visitante, las palabras clave",
  "howItWorksPage.metadata.title": "¿Cómo funciona?",
  "howItWorksPage.metadata.description": "Descripción del funcionamiento",
  "howItWorksPage.metadata.keywords": "Cómo funcionan las palabras clave",
  "loginPage.metadata.title": "Registro",
  "loginPage.metadata.description": "Descripción del registro",
  "loginPage.metadata.keywords": "Palabras clave de registro",
  "resetPasswordPage.metadata.title": "Restablecer contraseña",
  "resetPasswordPage.metadata.description": "Descripción del restablecimiento de contraseña",
  "resetPasswordPage.metadata.keywords": "Restablecer contraseña de palabra clave",
  "logoutPage.metadata.title": "Cierre de sesión",
  "logoutPage.metadata.description": "Descripción del cierre de sesión",
  "logoutPage.metadata.keywords": "Palabras clave de cierre de sesión",
  "notFoundPage.metadata.title": "404 - ¡Página no encontrada!",
  "notFoundPage.metadata.description": "404 - Descripción de la página no encontrada",
  "priceListPage.metadata.title": "Lista de precios",
  "priceListPage.metadata.description": "Descripción de la lista de precios",
  "priceListPage.metadata.keywords": "Lista de precios por palabra clave",
  "registrationPage.metadata.title": "Inscripción",
  "registrationPage.metadata.description": "Descripción de la inscripción",
  "registrationPage.metadata.keywords": "Registro de palabras clave",
  "reservationHistoryPage.metadata.title": "Sus reservas",
  "reservationHistoryPage.metadata.description": "Descripción de sus reservas",
  "reservationHistoryPage.metadata.keywords": "Sus palabras clave de reserva",
  "searchMapPage.metadata.title": "Resultados de la búsqueda",
  "searchMapPage.metadata.description": "Descripción de los resultados de la búsqueda",
  "searchMapPage.metadata.keywords": "Resultados de la búsqueda por palabra clave",
  "searchPage.metadata.title": "Resultados de la búsqueda",
  "searchPage.metadata.description": "Descripción de los resultados de la búsqueda",
  "searchPage.metadata.keywords": "Resultados de la búsqueda por palabra clave",
  "privacyPolicyPage.metadata.title": "Política de privacidad",
  "cookiesPolicyPage.metadata.title": "Política de cookies",
  "shortcut.isClosed": "cerrado",
  "shortcut.monday": "Lu.",
  "shortcut.tuesday": "Ma.",
  "shortcut.wednesday": "Mi.",
  "shortcut.thursday": "Ju.",
  "shortcut.friday": "Vi.",
  "shortcut.saturday": "Sá.",
  "shortcut.sunday": "Do.",
  "defaultValues.restaurant": "Restaurante",
  "restaurantPromotionInfoModal.button.use": "Utilice",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Detalles de la oferta",
  "previewImageModal.component.anchor.close": "Cerrar",
  "previewRestaurantModal.component.anchor.close": "Cerrar",
  "deleteAccountModal.component.header": "Eliminar cuenta",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "¿Está seguro de que desea eliminar su cuenta? También se eliminará su historial de reservas.",
  "deleteAccountModal.component.button.confirm": "Aprobar",
  "deleteAccountModal.component.button.cancel": "Anuncie",
  "cancellationReservationModal.component.header": "Anulación de la reserva",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "¿Está seguro de que desea cancelar su reserva en el restaurante {{restaurantName}} a las {{reservationHour}} del {{reservationDate}}?",
  "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
    "La tarifa asociada a la reserva ha sido transferida al restaurante. Póngase en contacto directamente con el restaurante para una posible recuperación.",
  "cancellationReservationModal.component.button.confirm": "Aprobar",
  "cancellationReservationModal.component.button.cancel": "Anuncie",
  "searchResultItem.component.label.availableHours": "Horas disponibles",
  "searchResultItem.component.label.futureReservationDaysError":
    "Las reservas a distancia son posibles para {{days}} días por adelantado",
  "searchResultItem.component.label.noAvailableTables":
    "Para los parámetros de búsqueda seleccionados (fecha, hora, número de personas), este restaurante no ofrece mesas para reservar.",
  "searchResultItem.component.button.book": "Reserve",
  "searchResultItem.component.button.cancel": "Anuncie",
  "searchResultItem.component.button.see": "Véase",
  "passwordFormModal.component.header": "Introduzca la contraseña de su cuenta",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Introduzca su contraseña para confirmar la acción.",
  "passwordFormModal.component.button.confirm": "Aprobar",
  "passwordFormModal.component.button.cancel": "Anuncie",
  "cuisine.romania": "Cocina rumana",
  "cuisine.american": "Cocina americana",
  "cuisine.arabic": "Cocina árabe",
  "cuisine.chinese": "Cocina china",
  "cuisine.polish": "Cocina polaca",
  "cusine.chinese": "Cocina china",
  "cusine.polish": "Cocina polaca",
  "component.cookiesPolicyLine.text":
    "Este sitio web utiliza cookies para ofrecerle el máximo nivel de servicio. Si continúa navegando, consideramos que acepta su uso.",
  "component.cookiesPolicyLine.privacyPolicyText": "Política de privacidad",
  "component.header.menu.mainPage": "Reserve",
  "component.header.menu.forRestaurants": "Para restaurantes",
  "component.header.menu.portal.restaurant": "Portal restauracji",
  "component.header.menu.forRestaurants.offer": "Nuestra oferta",
  "component.header.menu.blog": "Noticias MOJSTOLIK.PL",
  "component.header.menu.christmas": "OFERTAS DE VACACIONES",
  "component.header.menu.forGuest": "Para el huésped",
  "component.header.menu.howItWorks": "¿Cómo funciona?",
  "component.header.menu.priceList": "Lista de precios",
  "component.header.menu.contact": "Póngase en contacto con nosotros",
  "component.header.menu.documents": "Documentos",
  "component.header.menu.about": "O en",
  "component.header.menu.login": "Conectarse",
  "component.header.menu.logout": "Cerrar sesión",
  "component.header.menu.reservationHistory": "Sus reservas",
  "component.header.menu.yourAccount": "Su cuenta",
  "component.header.menu.covid": "Manuales COVID-19",
  "component.header.menu.covid.restaurant": "Guía de restaurantes",
  "component.header.menu.covid.client": "Guía del visitante",
  "component.header.menuMobile.covid.restaurant": "Guía COVID-19 para restaurantes",
  "component.header.menuMobile.covid.client": "Guía COVID-19 para visitantes",
  "component.header.menu.events": "Eventos",
  "component.header.menu.event": "Evento -",
  "component.footer.menu.header.forClients": "Acerca de MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Información",
  "component.footer.menu.header.yourAccount": "Su cuenta",
  "component.footer.menu.header.socialMedia": "Social media",
  "component.footer.menu.header.applicationDownload": "Descargar la solicitud",
  "component.footer.menu.forRestaurants": "Para restaurantes",
  "component.footer.menu.forGuest": "Para el huésped",
  "component.footer.menu.howItWorks": "¿Cómo funciona?",
  "component.footer.menu.priceList": "Lista de precios",
  "component.footer.menu.contact": "Póngase en contacto con nosotros",
  "component.footer.menu.documents": "Documentos",
  "component.footer.menu.about": "O en",
  "component.footer.menu.login": "Conectarse",
  "component.footer.menu.reservationHistory": "Sus reservas",
  "component.footer.menu.yourAccount": "Su cuenta",
  "component.footer.menu.logout": "Cerrar sesión",
  "component.footer.menu.registration": "Inscripción",
  "component.footer.menu.privacyPolicy": "Política de privacidad",
  "component.footer.menu.cookiesPolicy": "Política de cookies",
  "indexPage.background.header": "Encuentra los mejores restaurantes de tu ciudad,",
  "indexPage.header.currentPromotions": "Ofertas especiales y eventos",
  "indexPage.header.currentPromotionsExtend": "en su ubicación",
  "indexPage.header.blogs": "Artículos destacados",
  "indexPage.header.mostPopular": "Más populares",
  "indexPage.header.mostPopularExtend": "en su ubicación",
  "indexPage.background.headerExtend": "y reserve mesa:",
  "indexPage.input.label.location": "Ubicación",
  "indexPage.input.label.date": "Datos",
  "indexPage.input.label.hour": "Hora",
  "indexPage.input.label.persons": "Número de personas",
  "indexPage.input.label.persons.optionExtend": "pers.",
  "indexPage.input.label.restaurantType": "Tipo de cocina",
  "indexPage.input.label.rating": "Evaluación",
  "indexPage.input.label.rating.optionExtend": "estrellas.",
  "indexPage.input.label.priceLevel": "Nivel de precios",
  "indexPage.input.label.searchByName": "Buscar por nombre",
  "indexPage.input.placeholder.select": "Seleccione",
  "indexPage.input.placeholder.type": "Entre en",
  "indexPage.input.placeholer.typeInOrSelect": "Escriba o seleccione",
  "indexPage.input.noData": "No hay resultados.",
  "indexPage.button.search": "Buscar en",
  "indexPage.button.showAdditionalFilters": "Filtros adicionales",
  "indexPage.button.hideAdditionalFilters": "Ocultar filtros adicionales",
  "indexPage.button.use": "Utilice",
  "indexPage.button.promotion": "promoción",
  "indexPage.button.bookNow": "Reserve",
  "indexPage.button.readMore": "Leer",
  "accountPage.header": "Su cuenta",
  "accountPage.subheader": "Cambie sus datos personales para agilizar el proceso de tramitación.",
  "accountPage.headerInColumn.yourPersonalData": "Sus datos",
  "accountPage.headerInColumn.yourPassword": "Tu contraseña",
  "accountPage.input.label.firstName": "Nombre",
  "accountPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "accountPage.input.label.lastName": "Nombre",
  "accountPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "accountPage.input.label.phoneNumber": "Número de teléfono",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.label.email": "Correo electrónico",
  "accountPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "accountPage.paragraph.optionalFields": "Campos opcionales",
  "accountPage.input.label.birthDate": "Fecha de nacimiento",
  "accountPage.input.placeholder.birthDate": "Por ejemplo, 1976-10-25",
  "accountPage.input.label.postalCode": "Código postal",
  "accountPage.input.placeholder.postalCode": "Por ejemplo, 03-123",
  "accountPage.input.label.city": "Ciudad",
  "accountPage.input.placeholder.city": "Por ejemplo, Varsovia",
  "accountPage.input.label.streetAndLocalNumber": "Calle y número de piso",
  "accountPage.input.placeholder.streetAndLocalNumber": "Por ejemplo, Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Métodos de comunicación:",
  "accountPage.button.save": "Guardar",
  "accountPage.button.deleteAccount": "Eliminar cuenta",
  "accountPage.input.label.oldPassword": "Antiguo eslogan",
  "accountPage.input.label.newPassword": "Nueva contraseña",
  "accountPage.input.label.newPasswordRepeat": "Nueva contraseña (repetir)",
  "accountPage.button.changePassword": "Cambiar contraseña",
  "accountPage.message.personalDataUpdated": "Se han actualizado los datos personales.",
  "accountPage.message.accountPermanentlyDeleted": "Tu cuenta ha sido eliminada permanentemente.",
  "accountPage.message.passwordChanged": "La contraseña ha sido modificada.",
  "accountPage.label.changePasswordNotPossible":
    "No es posible cambiar la contraseña porque te hayas conectado a través de Facebook o Google.",
  "accountPage.toast.error.disableBothChannels":
    "¡Al menos un método de comunicación (SMS, CORREO ELECTRÓNICO) debe permanecer activo!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Habilitar el método de comunicación requiere la confirmación de la exactitud de los datos. ¡Guarda los cambios para recibir un enlace de verificación en la nueva dirección de correo electrónico!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Habilitar el método de comunicación requiere la confirmación de la exactitud de los datos. ¡Guarda los cambios para recibir un enlace de verificación por SMS en tu nuevo número de teléfono!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Para habilitar el nuevo método de comunicación, confirma tus datos haciendo clic en el enlace de activación enviado a tu dirección de correo electrónico.",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Para habilitar el nuevo método de comunicación, confirma tus datos haciendo clic en el enlace de activación enviado por SMS al número de teléfono proporcionado.",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Para verificar tus datos, haz clic en el enlace de verificación enviado a tu dirección de correo electrónico.",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Para verificar tus datos, haz clic en el enlace de verificación enviado por SMS a tu número de teléfono.",
  "accountPage.channels.disclaimer":
    "* Para editar el número de teléfono o la dirección de correo electrónico, es necesario desactivar el módulo de comunicación que se está editando.",
  "accountPage.confirmSms.header": "Confirmación de la autenticidad de los datos",
  "accountPage.confirmSms.paragraph":
    "Para confirmar tus datos, introduce el código enviado por SMS al número de teléfono proporcionado.",
  "reservationHistoryPage.sendMessage": "Enviar un mensaje",
  "reservationHistoryPage.send": "Enviar",
  "reservationHistoryPage.cancel": "Anuncie",
  "reservationChatPage.send": "Enviar",
  "reservationChatPage.writeMessage": "Escriba su mensaje",
  "reservationHistoryPage.header": "Sus reservas",
  "reservationHistoryPage.subheader":
    "Aquí puede consultar su historial de reservas y gestionar las reservas futuras.",
  "reservationHistoryPage.paragraph.personsShortcut": "pers.",
  "reservationHistoryPage.button.revoke": "Cancelar",
  "reservationHistoryPage.button.pay": "Pagar",
  "reservationHistoryPage.paragraph.additionalDescription": "Comunicación con el restaurante",
  "reservationHistoryPage.paragraph.date": "Datos",
  "reservationHistoryPage.paragraph.duration": "Duración",
  "reservationHistoryPage.paragraph.hour": "Hora",
  "reservationHistoryPage.paragraph.persons": "Número de personas",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "La reserva no existe",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "La lista de reservas está vacía.",
  "reservationHistoryPage.paragraph.status": "Estado",
  "reservationHistoryPage.paragraph.invoice": "Factura",
  "reservationPage.paragraph.lateInfo":
    "Tenga en cuenta que, en caso de retraso, la mesa esperará al cliente 15 minutos.",
  "reservationHistoryPage.paragraph.payment.status": "Estado de los pagos",
  "searchPage.header": "Resultados de la búsqueda",
  "searchPage.button.map": "Mapa",
  "searchPage.button.list": "Lista",
  "searchPage.paragraph.noData": "No hay resultados para los criterios indicados.",
  "searchMapPage.map.button.refresh": "Actualizar el mapa",
  "searchMapPage.header": "Resultados de la búsqueda",
  "searchMapPage.button.map": "Mapa",
  "searchMapPage.button.list": "Lista",
  "loginPage.header": "Registro",
  "loginPage.subheader":
    "Como usuario registrado, puede reservar mesas más rápidamente y consultar su historial de reservas.",
  "loginPage.columns.header.standardLogin": "Inicio de sesión estándar",
  "loginPage.columns.header.socialLogin": "Iniciar sesión con",
  "loginPage.input.label.phoneOrEmail": "Número de teléfono/dirección de correo electrónico",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.input.label.password": "Contraseña",
  "loginPage.input.placeholder.password": "",
  "loginPage.anchor.passwordReset": "Restablecer contraseña",
  "loginPage.button.logIn": "Conectarse",
  "loginPage.paragraph.dontHaveAccountYet": "¿Aún no tiene cuenta?",
  "modal.login.dontHaveAccountYet": "Aún no tienes cuenta",
  "loginPage.paragraph.registerNow": "Regístrese",
  "loginPage.button.register": "Regístrese en",
  "loginPage.message.logged": "Reblogueado.",
  "loginPage.message.loggedViaFacebook": "Reblogueado vía Facebook.",
  "loginPage.message.loggedViaApple": "Reblogueado por Apple.",
  "loginPage.message.loggedViaGoogle": "Reblogueado vía Google.",
  "loginPage.paragraph.alreadyLoggedIn": "Ha iniciado sesión.",
  "resetPasswordPage.header": "Restablecer contraseña",
  "resetPasswordPage.subheader":
    "Complete el formulario a continuación, y le enviaremos una nueva contraseña para su cuenta por SMS o correo electrónico.",
  "resetPasswordPage.subheader2": "Te enviaremos una nueva contraseña a tu número de teléfono",
  "resetPasswordPage.input.label.phoneOrEmail": "Ingrese su número de teléfono o dirección de correo electrónico",
  "resetPasswordPage.button.confirm": "Confirme",
  "resetPasswordPage.toast.passwordChanged":
    "La nueva contraseña ha sido enviada al número de teléfono o dirección de correo electrónico proporcionado.",
  "resetPasswordPage.toast.error.passwordChanged": "El número de teléfono o dirección de correo electrónico proporcionado es incorrecto.",
  "cookiesPolicyPage.header": "Política de cookies",
  "cookiesPolicyPage.subheader": "Información relevante.",
  "cookiesPolicyPage.point.1":
    "El sitio web no recoge ninguna información automáticamente, salvo la contenida en las cookies.",
  "cookiesPolicyPage.point.2":
    'Las cookies (denominadas "cookies") son datos informáticos, en particular archivos de texto, que se almacenan en el equipo terminal del Usuario del Servicio y están destinados a ser utilizados en el Sitio Web. Las cookies suelen contener el nombre del sitio web del que proceden, el tiempo que permanecen almacenadas en el equipo terminal y un número único.',
  "cookiesPolicyPage.point.3":
    "La entidad que coloca cookies en el equipo terminal del Usuario del Sitio Web y accede a ellas es el propietario de mojstolik.pl Table4Rest Sp. z o.o. con domicilio social en ul. Ksawerów 21, 02-656 Varsovia.",
  "cookiesPolicyPage.point.4": "Las cookies se utilizan para:",
  "cookiesPolicyPage.point.4a":
    "adaptar el contenido de las páginas del Sitio Web a las preferencias del Usuario y optimizar el uso de los sitios web; en particular, estos archivos permiten reconocer el dispositivo del Usuario del Sitio Web y mostrar el sitio web de forma adecuada y adaptada a sus necesidades individuales;",
  "cookiesPolicyPage.point.4b":
    "la creación de estadísticas que nos ayuden a comprender el uso que hacen los visitantes del sitio web, lo que nos permite mejorar su estructura y contenido;",
  "cookiesPolicyPage.point.4c":
    "mantener una sesión del Usuario del Servicio (después de iniciar sesión), gracias a la cual el Usuario no tiene que volver a introducir su nombre de usuario y contraseña en cada subpágina del Servicio;",
  "cookiesPolicyPage.point.5":
    'El Sitio Web utiliza dos tipos principales de cookies: "de sesión" (cookies de sesión) y "permanentes" (cookies persistentes). Las cookies de "sesión" son archivos temporales que se almacenan en el equipo terminal del Usuario hasta que se desconecta, abandona el sitio web o apaga el software (navegador web). Las cookies "permanentes" se almacenan en el equipo terminal del Usuario durante el tiempo especificado en los parámetros de las cookies o hasta que el Usuario las elimina.',
  "cookiesPolicyPage.point.6": "En el Servicio se utilizan los siguientes tipos de cookies:",
  "cookiesPolicyPage.point.6a":
    'cookies "necesarias" para permitir el uso de los servicios disponibles en el Sitio Web, por ejemplo, cookies de autenticación utilizadas para los servicios que requieren autenticación en el Sitio Web;',
  "cookiesPolicyPage.point.6b":
    "cookies utilizadas con fines de seguridad, por ejemplo, para detectar un uso indebido de la autenticación en el sitio web;",
  "cookiesPolicyPage.point.6c":
    'cookies de "rendimiento", que permiten recopilar información sobre cómo se utilizan las páginas del sitio web;',
  "cookiesPolicyPage.point.6d":
    'cookies "funcionales", que permiten "recordar" los ajustes elegidos por el usuario y personalizar la interfaz del usuario, por ejemplo, en relación con el idioma o la región de origen elegidos por el usuario, el tamaño de letra, el diseño del sitio web, etc;',
  "cookiesPolicyPage.point.6e":
    'cookies "publicitarias", que permiten ofrecer a los Usuarios contenidos publicitarios más adaptados a sus intereses.',
  "cookiesPolicyPage.point.7":
    "En muchos casos, el software de navegación web (navegador web) permite almacenar cookies en el dispositivo terminal del Usuario de forma predeterminada. Los Usuarios del Sitio Web pueden cambiar su configuración relativa a las cookies en cualquier momento. Estos ajustes pueden modificarse, en particular, para bloquear el tratamiento automático de cookies en la configuración de un navegador web o informar sobre su colocación cada vez en el equipo de un Usuario del Servicio. La información detallada sobre la posibilidad y los métodos de uso de cookies está disponible en la configuración de su software (navegador web).",
  "cookiesPolicyPage.point.8":
    "El Operador del Sitio Web le informa de que las restricciones en el uso de cookies pueden afectar a algunas de las funcionalidades disponibles en el Sitio Web.",
  "privacyPolicyPage.header": "Política de privacidad",
  "privacyPolicyPage.subheader": "Información relevante.",
  "privacyPolicyPage.point.one": "DISPOSICIONES GENERALES",
  "privacyPolicyPage.point.one.1":
    "El administrador de los datos es Table4Rest Sp. z o.o. con domicilio social en Varsovia 02-656, Ksawerów St. 21, inscrita en el Registro de Empresarios con el número KRS: 0000752110, cuyos archivos de registro están en poder del TRIBUNAL DEL REGISTRO DE LA CIUDAD DE VARSOVIA EN VARSOVIA, XIII DEPARTAMENTO ECONÓMICO DEL REGISTRO NACIONAL NIP: 5213842139 REGON: 381529521.",
  "privacyPolicyPage.point.one.2":
    "Para la interpretación de los términos, se aplicará el glosario de las Condiciones Generales o lo descrito en la Política de Privacidad (cuando se desprenda directamente de la descripción).",
  "privacyPolicyPage.point.one.3":
    'A efectos de una mejor percepción de la Política de Privacidad, el término "Usuario" se sustituye por "usted", "Administrador" por "nosotros". El término "RODO" significa Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE.',
  "privacyPolicyPage.point.one.4":
    "Respetamos el derecho a la privacidad y cuidamos la seguridad de los datos. Para ello utilizamos, entre otros, un protocolo de encriptación de comunicaciones seguras (SSL).",
  "privacyPolicyPage.point.one.5":
    "Los datos personales facilitados en el formulario de la página de aterrizaje se tratan de forma confidencial y no son visibles para personas no autorizadas.",
  "privacyPolicyPage.point.two": "CONTROLADOR",
  "privacyPolicyPage.point.two.1":
    "El proveedor de servicios es el administrador de las personas que han utilizado el formulario de contacto del sitio web o que han utilizado otra forma de comunicación (correo electrónico, teléfono, aplicación móvil, complemento de llamada de pedido, otras formas de comunicación).",
  "privacyPolicyPage.point.two.2": "Los datos personales son objeto de tratamiento:",
  "privacyPolicyPage.point.two.2a":
    "a. de conformidad con la legislación sobre protección de datos,",
  "privacyPolicyPage.point.two.2b": "b. de conformidad con la Política de Privacidad aplicada,",
  "privacyPolicyPage.point.two.2c":
    "c. en la medida y para los fines necesarios para el establecimiento, la formación, la modificación o la rescisión del Acuerdo y la correcta prestación de los Servicios,",
  "privacyPolicyPage.point.two.2d":
    "d. en la medida y para los fines necesarios para la satisfacción de intereses legítimos (fines legítimos) y el tratamiento no perjudique los derechos y libertades del interesado.",
  "privacyPolicyPage.point.two.3":
    "Todo interesado (si nosotros somos el responsable del tratamiento) tiene derecho de acceso, rectificación, supresión o limitación del tratamiento, derecho de oposición y derecho a presentar una reclamación ante una autoridad de control.",
  "privacyPolicyPage.point.two.4":
    "Es posible ponerse en contacto con la persona que supervisa el tratamiento de los datos personales en la organización del Proveedor de Servicios por correo electrónico en: kontakt@mojstolik.pl",
  "privacyPolicyPage.point.two.5":
    "Nos reservamos el derecho a procesar sus datos tras la rescisión del Acuerdo o la retirada del consentimiento sólo en la medida en que sea necesario para hacer valer posibles reclamaciones ante los tribunales o si la legislación nacional, de la UE o internacional nos obliga a conservar los datos.",
  "privacyPolicyPage.point.two.6":
    "El Proveedor de Servicios tendrá derecho a poner los datos personales del Usuario y otros datos del Usuario a disposición de las entidades autorizadas en virtud de la legislación pertinente (por ejemplo, organismos encargados de hacer cumplir la ley).",
  "privacyPolicyPage.point.two.7":
    "La supresión de datos personales puede tener lugar como consecuencia de la retirada del consentimiento o de la presentación de una objeción legalmente admisible al tratamiento de datos personales.",
  "privacyPolicyPage.point.two.8":
    "El Proveedor de Servicios no pondrá los datos personales a disposición de entidades distintas de las autorizadas por la legislación pertinente.",
  "privacyPolicyPage.point.two.9":
    "Los datos personales sólo son procesados por personas autorizadas por nosotros o por procesadores con los que colaboramos estrechamente.",
  "restaurantPage.button.pageWWW": "Página web",
  "restaurantPage.button.seeMenu": "Ver el menú",
  "restaurantPage.button.confirm": "Confirme",
  "restaurantPage.button.cancel": "Anuncie",
  "restaurantPage.button.choose": "Seleccione",
  "restaurantPage.button.back": "Devolución",
  "restaurantPage.button.showMore": "Ver más",
  "restaurantPage.button.showMore.promotion": "Seguir leyendo",
  "restaurantPage.button.showLess": "Mostrar menos",
  "restaurantPage.button.goToHomePage": "Volver a la página de inicio",
  "restaurantPage.button.reservationDetails": "Detalles de la reserva",
  "restaurantPage.button.goToPaymentPage": "Ir al panel de pago",
  "restaurantPage.paymentPage.info":
    "Será redirigido inmediatamente al operador de pago Przelewy24.pl",
  "restaurantPage.header.restaurantDescription": "Descripción del restaurante",
  "restaurantPage.header.bookingPolicy": "Política de reservas",
  "restaurantPage.header.gallery": "Galería de fotos",
  "restaurantPage.header.yourReservation": "Su reserva",
  "restaurantPage.header.yourPersonalData": "Sus datos",
  "restaurantPage.header.ctnWithoutLogin": "Continuar sin iniciar sesión",
  "restaurantPage.header.login": "Registro",
  "restaurantPage.button.login": "Conectarse",
  "restaurantPage.button.orLoginWith": "O conéctese utilizando",
  "restaurantPage.header.createAccount": "Crea una cuenta en MojStolik.es y benefíciate:",
  "restaurantPage.header.createAccount.benefit.1": "Forma rápida de reservar",
  "restaurantPage.header.createAccount.benefit.2": "Historial completo de reservas",
  "restaurantPage.header.createAccount.benefit.3": "Acceso a promociones únicas",
  "restaurantPage.button.createAccount": "Crear una cuenta MojStolik.pl",
  "restaurantPage.header.statements": "Declaraciones",
  "restaurantPage.header.statement": "Declaración",
  "restaurantPage.header.promotions": "Ofertas especiales y eventos",
  "restaurantPage.header.use": "Utilice",
  "restaurantPage.header.cancel": "Ríndete",
  "restaurantPage.header.reservationConfirmation": "Confirmación de reserva",
  "restaurantPage.header.reservationsPersons": "Reservas de {{min}} a {{max}} personas",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Introduce el código del SMS que enviamos a tu número de teléfono para completar el proceso de reserva de mesa.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Introduce el código del mensaje que enviamos a tu dirección de correo electrónico para completar el proceso de reserva de mesa.",
  "restaurantPage.input.label.code": "Introduce el código del día",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "No hay mesas disponibles para los criterios seleccionados (fecha, número de personas, promoción). Por favor, seleccione otra fecha, número de personas o promoción.",
  "restaurantPage.paragraph.noAvailableHours":
    "No hay mesas libres en los criterios seleccionados.",
  "restaurantPage.paragraph.noAvailableHoursExt": "Elige otra fecha o otro número de personas.",
  "restaurantPage.input.label.date": "Datos",
  "restaurantPage.input.label.hour": "Hora",
  "restaurantPage.input.label.persons": "Número de personas",
  "restaurantPage.input.label.persons.optionExtend": "pers.",
  "restaurantPage.input.label.firstName": "Nombre",
  "restaurantPage.input.label.vatIdNumber": "PIN",
  "restaurantPage.input.label.address": "Dirección",
  "restaurantPage.input.label.companyName": "Nombre de la empresa",
  "restaurantPage.input.label.street": "Calle",
  "restaurantPage.input.label.buildingNumber": "Edificio",
  "restaurantPage.input.label.localNumber": "Local",
  "restaurantPage.input.label.postalCode": "Código postal",
  "restaurantPage.input.label.city": "Ciudad",
  "restaurantPage.input.label.country": "País",
  "restaurantPage.get.company.data": "Descargar",
  "restaurantPage.input.label.lastName": "Nombre",
  "restaurantPage.input.label.phoneNumber": "Número de teléfono",
  "restaurantPage.input.label.email": "Correo electrónico",
  "restaurantPage.input.label.password": "Introduzca la contraseña",
  "restaurantPage.input.label.passwordRepeat": "Repetir contraseña",
  "restaurantPage.input.label.description": "Notas de reserva",
  "agreements.allAgreements": "Marcar todo",
  "agreements.invoice": "Introduzca los datos de su factura",
  "restaurantPage.input.label.personalData":
    "Declaro que conozco y acepto las disposiciones de los Términos y Condiciones para la prestación de servicios por parte de MojStolik a través de la Solicitud de Cliente.",
  "restaurantPage.input.label.personalData.extension": " Términos y condiciones de MojStolik",
  "restaurantPage.input.label.readWithdrawal":
    "He leído los términos y condiciones de MojStolik.es, en particular las instrucciones sobre el derecho de desistimiento.",
  "restaurantPage.input.label.readWithdrawal.extension": "Términos y condiciones de mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "Deseo recibir promociones, ofertas especiales, ofertas de concursos u otros contenidos de marketing de MojStolik, incluida información personalizada sobre los servicios y productos de MojStolik disponibles en la Aplicación para clientes, mediante comunicación electrónica. Puede retirar este consentimiento en cualquier momento.",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Deseo recibir promociones, ofertas especiales, ofertas de concursos u otros contenidos de marketing de MojStolik, incluida información personalizada para mí sobre los servicios y productos del Restaurante y las Entidades colaboradoras indicados en mojstolik.es, disponibles en la Solicitud del cliente, a través de comunicación electrónica. Puede retirar este consentimiento en cualquier momento.",
  "agreement.eventAgreement":
    "He leído y acepto los términos y condiciones de la oferta especial que se encuentra en el",
  "registrationPage.input.label.eventAgreementUrl.extension": "en este enlace",
  "restaurantPage.input.placeholder.typeIn": "Entre en",
  "restaurantPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "restaurantPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "restaurantPage.paragraph.fillOrLogin":
    "Rellena los datos restantes o descárgalos a través de Facebook, Google o Apple",
  "restaurantPage.paragraph.doYouWantToRegister":
    "Si desea crear una cuenta en MOJSTOLIK.PL, introduzca una contraseña",
  "restaurantPage.paragraph.dataIsIncorrect": "¿Los datos son incorrectos?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "El código ha sido reenviado por SMS. ¿No recibiste el código?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Autorízate a través del correo electrónico:",
  "restaurantPage.paragraph.sendCodeViaEmail.link":
    "Envía el código a la dirección de correo electrónico proporcionada.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent":
    "El código ha sido enviado a la dirección de correo electrónico:",
  "restaurantPage.paragraph.emailCommunicationAlert": "ADVERTENCIA: Los próximos mensajes relacionados con tu reserva serán enviados a la dirección de correo electrónico proporcionada.",
  "restaurantPage.anchor.sendCodeAgain": "Reenviar código",
  "restaurantPage.anchor.edit": "Editar",
  "restaurantPage.anchor.login": "Inicio de sesión",
  "restaurantPage.paragraph.or": " o",
  "restaurantPage.anchor.register": "regístrese en",
  "restaurantPage.paragraph.dontWontUsePromotion": "No quiero usar promociones",
  "restaurantPage.messages.codeResent": "Se ha vuelto a enviar el código SMS.",
  "restaurantPage.header.reservationRecieved": "Aceptación de reservas",
  "restaurantPage.summary.paragraph.firstLastName": "Nombre Apellido",
  "restaurantPage.summary.paragraph.phoneNumber": "Número de teléfono:",
  "restaurantPage.summary.paragraph.email": "Correo electrónico:",
  "restaurantPage.summary.paragraph.restName": "Nombre del restaurante:",
  "restaurantPage.summary.paragraph.date": "Fecha límite:",
  "restaurantPage.summary.paragraph.date.time": "horas.",
  "restaurantPage.summary.paragraph.duration": "Duración:",
  "restaurantPage.summary.paragraph.persons": "Número de visitantes:",
  "restaurantPage.summary.paragraph.totalCost": "Total pagos anticipados:",
  "restaurantPage.summary.special.offer": "Oferta especial:",
  "restaurantPage.summary.paragraph.state": "Estado:",
  "restaurantPage.paragraph.tableReserved": "Se ha reservado una mesa",
  "reservation.status.Canceled": "Cancelado",
  "reservation.status.Completed": "Reserva efectuada",
  "reservation.status.Confirmed": "Reserva confirmada",
  "reservation.status.Delayed": "Retraso",
  "reservation.status.NotCompleted": "Reserva no realizada",
  "reservation.status.Rejected": "Reserva anulada",
  "reservation.status.WaitingForApproval": "Reserva pendiente de aprobación",
  "reservation.status.WaitingForConfirmation": "A la espera de",
  "restaurantPage.paragraph.tableReservedDescription": "Su mesa está reservada para una hora.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "en",
  "restaurantPage.paragraph.tableWaitingForApproval": "Reserva pendiente de aprobación",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1": "Su reserva por hrs.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "Si no recibe confirmación de su reserva en unas horas, póngase en contacto directamente con el restaurante.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "en",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "Una vez confirmada la reserva, se enviará un mensaje con un enlace de pago al número de teléfono indicado. La reserva será válida una vez recibida la confirmación del pago",
  "restaurantPage.paragraph.tableReservedPaymentInformation": "Las reservas deben pagarse",
  "restaurantPage.input.label.footer":
    "MojStolik utiliza diversos canales de comunicación electrónica y lleva a cabo su actividad mediante correo electrónico, teléfono, SMS y MMS. El administrador de sus datos personales es Table4Rest spółka z ograniczoną odpowiedzialnością con domicilio social en Varsovia, 02-656 Varsovia ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Sus datos personales se procesarán con el fin de dirigirle contenidos de marketing a través de canales de comunicación electrónica. Para más información sobre el tratamiento de sus datos personales, incluidos sus derechos, consulte la Política de privacidad.",
  "forGuestPage.header": "Para el huésped",
  "howItWorksPage.header": "¿Cómo funciona?",
  "howItWorksPage.subheader": "Paso a paso",
  "howItWorksPage.content.title": "Reservas desde la aplicación móvil",
  "howItWorksPage.content.step1": "Primer paso",
  "howItWorksPage.content.step1.content": "El cliente busca restaurantes en la aplicación móvil y",
  "howItWorksPage.content.step1.content.strong": "ve las mesas disponibles en tiempo real.",
  "howItWorksPage.content.step2": "Paso 2",
  "howItWorksPage.content.step2.content.strong":
    "El gestor puede ver la solicitud de reserva en su panel",
  "howItWorksPage.content.step2.content":
    " Sabe si la solicitud procede de un nuevo cliente o de un cliente habitual y si hay alguna preferencia de mesa.",
  "howItWorksPage.content.step3": "Paso 3",
  "howItWorksPage.content.step3.content": "Un cliente entra en un restaurante donde",
  "howItWorksPage.content.step3.content.strong":
    "es saludado por su nombre. El personal dispone de todos los datos del cliente",
  "howItWorksPage.content.step3.contentb":
    " - sabe si es su visitante habitual o si les visita por primera vez.",
  "howItWorksPage.phone.title": "Reserva telefónica",
  "howItWorksPage.content.phone.step1": "Primer paso",
  "howItWorksPage.content.phone.step1.content1": "Klient",
  "howItWorksPage.content.phone.step1.content.strong": "llama al restaurante de tu elección",
  "howItWorksPage.content.phone.step1.content2": "con una solicitud de reserva.",
  "howItWorksPage.content.phone.step2": "Paso 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "El gestor puede ver la disponibilidad de mesas en tiempo real en su cuadro de mandos",
  "howItWorksPage.content.phone.step2.content":
    ". Cuando un gestor introduce el número de teléfono de un cliente, ve inmediatamente los datos del cliente o completa/introduce los datos de un nuevo cliente.",
  "howItWorksPage.content.phone.step3": "Paso 3",
  "howItWorksPage.content.phone.step3.content": "Un cliente entra en un restaurante donde",
  "howItWorksPage.content.phone.step3.content.strong":
    "el personal le saluda por su nombre, tiene todos los datos del cliente",
  "howItWorksPage.content.phone.step3.contentb":
    " - sabe si es su visitante habitual o si les visita por primera vez.",
  "priceListPage.header": "Lista de precios",
  "priceListPage.content.link.1": "Para más información sobre precios",
  "priceListPage.content.link.2": "Póngase en contacto con",
  "contactPage.header": "Póngase en contacto con nosotros",
  "contactPage.subheader": "Escríbanos",
  "contactPage.content.title": "Datos de contacto",
  "documentsPage.header": "Documentos",
  "documentsPage.header.for.clients": "Para el cliente",
  "documentsPage.header.for.restaurants": "Para restaurantes",
  "documentsPage.subheader": "Información pertinente",
  "documentsPage.for.clients.document.1":
    "Normativa para la prestación de servicios por parte de MojStolik a través de la Aplicación para Clientes",
  "documentsPage.for.clients.document.2": "Información sobre el tratamiento de datos personales",
  "documentsPage.header.for.restaurants.document.1":
    "Condiciones de suministro de la aplicación MojStolik para restaurantes",
  "documentsPage.header.for.restaurants.document.2":
    "Contrato de encargo para el tratamiento de los datos personales del Restaurante",
  "documentsPage.header.for.restaurants.document.3": "Datos personales mojstolik.es",
  "aboutPage.header": "O en",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " - es un sistema polaco creado por un grupo de amigos - amantes secretos de la buena comida y del arte de recibir en restaurantes. Se trata de una solución tecnológica que ayuda a construir una comunidad en torno a lugares atractivos gracias a la buena cocina y al respeto por el tiempo y los hábitos de sus fieles comensales. MojStolik.pl es un grupo cada vez mayor de restaurantes que colaboran para que la gastronomía polaca se identifique con una calidad excelente, atención al detalle y respeto por los aficionados que pasan tiempo en la mesa con amigos, familiares o socios comerciales. Los fundadores del sistema tradujeron su pasión por los restaurantes al lenguaje tecnológico y crearon así un portal gastronómico y una aplicación que conecta a la gente en las mesas de los restaurantes más valorados y populares de Polonia.",
  "aboutPage.application.header1":
    " ¿Quiere reservar mesa en un restaurante? Ahora puede hacerlo a través de una aplicación en su teléfono o desde un portal de varios restaurantes.",
  "aboutPage.application.description1":
    "Mojstolik.pl es una nueva plataforma integral de reservas recientemente disponible para los restaurantes de Varsovia. Permite a los establecimientos de restauración gestionar cómodamente las reservas de mesas en tiempo real e integra todos los canales de comunicación con los clientes: teléfono, correo electrónico, la aplicación móvil MojStolik y el llamado walk-in. Gracias a él, los responsables de los restaurantes tienen acceso a una vista dinámica de la sala, deciden ellos mismos la distribución de la sala, el número de asientos disponibles y por cuánto tiempo se puede reservar cada mesa. Una ventaja adicional del sistema es el mecanismo de confirmación de reservas por SMS, con la opción de cancelar mediante un enlace y enviar recordatorios por SMS a los clientes.",
  "aboutPage.application.header2": "MojStolik.es: una tableta en lugar de un libro de reservas",
  "aboutPage.application.description2":
    "MojStolik.pl es también una herramienta que ayuda a los restaurantes a fidelizar a sus clientes. El sistema permite crear una base de datos de clientes, analizar el historial de reservas y el contador de visitas muestra el número de veces que una persona ha visitado el restaurante. Al hacer una reserva, los clientes tienen la opción de añadir comentarios como preferencias de mesa, platos favoritos o vinos. La nueva plataforma permite almacenar los datos de los clientes de forma segura de acuerdo con los requisitos RODO, algo que no hace el libro de reservas en papel que utilizan los restaurantes.",
  "aboutPage.application.description2b":
    "La aplicación la utilizan más de 100 restaurantes de toda Polonia, entre ellos: Stary Dom, Baczewskie Restaurant, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE'S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, y pronto se añadirán más.",
  "aboutPage.application.header3": "MojStolik: nueva aplicación para reservar mesa",
  "aboutPage.application.description3":
    " MojStolik es una nueva aplicación móvil que permite reservar mesa en un restaurante de forma rápida y cómoda. La app permite comprobar al instante la disponibilidad y reservar una mesa para una hora determinada, ya que se comunica con el restaurante en tiempo real. Puede añadir a su reserva una petición especial o un comentario sobre su mesa favorita en el restaurante, información sobre alergias u otras preferencias, y el restaurante puede utilizar la función de chat para comunicarle si su petición es posible. En la aplicación MojStolik también podemos consultar el menú actual del restaurante y acceder a promociones especiales que los restaurantes han preparado para nosotros.",
  "aboutPage.contact.data": "Datos de contacto",
  "aboutPage.contact.street": "ul.",
  "registrationPage.header": "Inscripción",
  "registrationPage.subheader":
    "Como usuario registrado, puede reservar mesas más rápidamente y consultar su historial de reservas.",
  "registrationPage.columns.header.registerStandard": "Registro estándar",
  "registrationPage.columns.header.registerWith": "Regístrese en",
  "registrationPage.input.label.firstName": "Nombre",
  "registrationPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "registrationPage.input.label.lastName": "Nombre",
  "registrationPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "registrationPage.input.label.phoneNumber": "Número de teléfono",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.label.email": "Correo electrónico",
  "registrationPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "registrationPage.paragraph.optionalFields": "Campos opcionales",
  "registrationPage.input.label.birthDate": "Fecha de nacimiento",
  "registrationPage.input.placeholder.birthDate": "Por ejemplo, 1976-10-25",
  "registrationPage.input.label.postalCode": "Código postal",
  "registrationPage.input.placeholder.postalCode": "Por ejemplo, 03-123",
  "registrationPage.input.label.city": "Ciudad",
  "registrationPage.input.placeholder.city": "Por ejemplo, Varsovia",
  "registrationPage.input.label.streetAndLocalNumber": "Calle y número de piso",
  "registrationPage.input.placeholder.streetAndLocalNumber": "Por ejemplo, Grójecka 130/105",
  "registrationPage.input.label.password": "Contraseña",
  "registrationPage.input.label.passwordRepeat": "Repetir contraseña",
  "registrationPage.header.statements": "Declaraciones",
  "registrationPage.input.label.agreementPersonalData":
    "Doy mi consentimiento para el tratamiento de mis datos personales por Table4Rest sp. z o.o. y los restaurantes y establecimientos de catering seleccionados por mí con el fin de ejecutar el objeto del contrato de conformidad con la información contenida en el documento.",
  "registrationPage.input.label.agreementPersonalData.extension":
    "Información sobre el tratamiento de los datos personales de los clientes",
  "registrationPage.input.label.agreementReadWithdrawal":
    "He leído los términos y condiciones de MojStolik.es, en particular las instrucciones sobre el derecho de desistimiento.",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Términos y condiciones de mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Consentimiento de comercialización",
  "registrationPage.input.label.agreementMarketing.extension": "Consentimiento",
  "registrationPage.input.label.promotionReadWithdrawal.extension": "Condiciones de la promoción",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "Consiento el tratamiento de mis datos personales por parte de los restaurantes y otros establecimientos de restauración seleccionados por mí con el fin de realizar una reserva en los mismos",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "en este enlace",
  "registrationPage.button.register": "Regístrese en",
  "registrationPage.message.sendCodeAgain": "Se ha vuelto a enviar el código SMS.",
  "registrationPage.message.accountCreated": "La cuenta ha sido creada.",
  "registrationPage.message.registrationTimeExpired": "Tiempo de registro expirado",
  "registrationPage.message.enterCodeFromSMS": "Transcribe el código del SMS.",
  "registrationPage.paragraph.timeIsOver": "El tiempo se ha acabado.",
  "registrationPage.paragraph.timeLeft": "Resto",
  "registrationPage.paragraph.seconds": "segundo(-a)",
  "registrationPage.header.SMSConfirmation": "Confirmación por SMS",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Introduzca el código SMS que le enviamos a su número de teléfono para completar su registro.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Introduzca el código SMS del día",
  "registrationPage.input.placeholder.code": "Introduzca el código",
  "registrationPage.paragraph.sendCodeAgain": "Se ha vuelto a enviar el código SMS.",
  "registrationPage.button.sendCodeAgain": "Reenviar código",
  "registrationPage.button.confirm": "Confirme",
  "notFoundPage.header": "Error 404",
  "notFoundPage.subheader": "URL solicitada",
  "notFoundPage.subheaderExtend": "no se encontró en el servidor.",
  "notFoundPage.subheaderExtend2":
    "Compruebe que la dirección que ha introducido es correcta o vaya a",
  "notFoundPage.subheaderExtend3": "página de inicio",
  "notFoundPage.button.goToHomePage": "Ir a la página de inicio",
  "global.toast.payment.count": "Productos requeridos no seleccionados",
  "paymentInfo.description.label": "Nombre",
  "paymentInfo.amount.label": "Precio unitario.",
  "paymentInfo.quantity.label": "Número",
  "paymentInfo.total.label": "Total",
  "paymentInfo.nocalculation": "No se exigen pagos",
  "paymentInfo.summarycost.label": "A pagar:",
  "paymentInfo.required.products": "Productos obligatorios",
  "paymentInfo.optional.products": "Productos opcionales",
  "paymentInfo.recalculate": "Recalcular",
  "paymentInfo.amount.to.paid": "Importe total a pagar",
  "paymentInfo.info":
    "El número total de productos obligatorios debe ser igual al número de personas de la reserva.",
  "paymentInfo.info1": "Aún tienes que elegir",
  "paymentInfo.info3": "producto",
  "paymentInfo.info4": "productos",
  "paymentInfo.package.title": "Paquete básico",
  "paymentInfo.select.product": "Seleccionar producto",
  "paymentInfo.select.basic.product": "Elija un producto básico",
  "paymentInfo.select.required.product": "Seleccionar productos obligatorios",
  "paymentInfo.too.much.products": "No puede seleccionar más productos",
  "restaurantPage.button.confirmAndPay": "Confirmar y pagar",
  "restaurantPage.button.closestTime": "Próxima fecha",
  "paymentInfo.title": "Cargos",
  "reservation.payment.status.Created": "Creado",
  "reservation.payment.status.WaitingForPayment": "A la espera de",
  "reservation.payment.status.ProcessingPayment": "Durante el proceso",
  "reservation.payment.status.Completed": "Realizado",
  "reservation.payment.status.Cancelled": "Cancelado",
  "reservation.payment.status.Expired": "Caducado",
  "reservation.payment.status.Unknown": "Estado desconocido",
  "custom.form.required": "Campo obligatorio",
  "custom.button.send": "Enviar",
  "custom.button.hide": "Colapso",
  "custom.button.show": "Ampliar",
  "reservation.confirmation.payment.count": "Recalcular productos",
  "reservation.confirmation.selected.promotion": "Promoción seleccionada",
  "forRestaurantsPage.form.toast.success": "Sus datos de contacto han sido enviados.",
  "forRestaurantsPage.form.toast.error": "Se ha producido un error, inténtelo de nuevo.",
  "forRestaurantsPage.form.email.error": "Correo electrónico incorrecto",
  "forRestaurantsPage.label.name": "NOMBRE",
  "forRestaurantsPage.label.restaurant": "RESTAURANTE:",
  "forRestaurantsPage.label.email": "CORREO ELECTRÓNICO:",
  "forRestaurantsPage.label.phone": "TELÉFONO:",
  "forRestaurantsPage.header.agreement": "DECLARACIONES",
  "forRestaurantsPage.label.agreement":
    " Doy mi consentimiento para el tratamiento de mis datos personales por Table4Rest sp. z o. o. con el fin de celebrar un contrato de prestación de servicios para el suministro y uso de la aplicación MojStolik.pl y para el envío de una oferta por vía electrónica.",
  "surveyPage.header.title": "Cuestionario para clientes",
  "surveyPage.header.description":
    "Su opinión es lo más importante para nosotros, es lo que nos permite crecer. Por favor, comparta su opinión con nosotros:",
  "surveyPage.button.complete": "Listo",
  "surveyPage.button.back": "Devolución",
  "surveyPage.button.next": "Más información en",
  "surveyPage.surveyCompleteMessage": "Gracias por completar la encuesta.",
  "surveyPage.emptySurveyMessage":
    "Se ha producido un error al descargar los datos de la encuesta. Vuelva a intentarlo más tarde.",
  "surveyPage.error": "Se ha producido un error.",
  "surveyPage.results.sending": "La publicación de sus respuestas está en curso...",
  "surveyPage.results.success": "Su cuestionario ha sido enviado",
  "surveyPage.filledAlert.header": "Este cuestionario ya ha sido cumplimentado"
};

export default frontendTranslationsES;

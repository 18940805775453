const frontendTranslationsEN = {
  /* -- seo --*/
  "seo.subpage.main.title": "Online table reservation – MojStolik.pl",
  "seo.subpage.main.description":
    "Book a table online in the most popular restaurants in Poland. Download the app, choose where you want to order a table and book. Its super easy with MojStolik.pl.",
  "seo.subpage.forRestaurant.title": "Restaurant booking platform - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl is a platform for managing reservations in restaurants. All bookings in 1 place. An additional platform for communication with restaurant guests.",
  "seo.subpage.aboutUs.title": "An ultra-simple application for those who like to eat out.",
  "seo.subpage.aboutUs.description":
    "Hundreds of the most popular and respected restaurants in the country and thousands of users using the MojStolik.pl platform. Let the table wait for you and never the other way around.",
  "seo.subpage.howItWork.title":
    "Download the MojStolik.pl application on your phone and book tables",
  "seo.subpage.howItWork.description":
    "It only takes a few clicks to have a restaurant table waiting for you. You decide when, where and with whom you want to have lunch or dinner. Download the app and book tables.",
  "seo.subpage.documents.title":
    "Check what benefits you will get from cooperation with MojStolik.pl",
  "seo.subpage.documents.description":
    "Are you wondering if its worth it? Check the most important information in MojStolik.pl documents. Agreements, marketing offers and benefits from cooperation.",
  "seo.subpage.privacyPolicy.title": "MojStolik.pl as the administrator of personal data",
  "seo.subpage.privacyPolicy.description":
    "Read important information and get to know the MojStolik.pl platform",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - discover restaurants in your area",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.pl uses cookies. Discover with us the most valued restaurants in your area.",
  "seo.subpage.registration.title": "Sign up and use the app today. MojStolik.pl",
  "seo.subpage.registration.description":
    "Table booking has never been so easy. Convenient payments, any time and number of people. Register and use MojStolik.pl today",
  "seo.subpage.login.title": "Log in to the application and choose restaurants from MojStolik.pl",
  "seo.subpage.login.description":
    "In our offer you will find the most popular restaurants in Poland. Download the app, log in and use the ultra-simple table booking system.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - Responsible Restaurant",
  "seo.subpage.covid.restaurant.description":
    "Solutions for restaurants that will help keep business in these difficult times!",
  "seo.subpage.covid.client.title": "COVID-19 - Responsible guests",
  "seo.subpage.covid.client.description":
    "A guide for responsible guests of a responsible restaurant",
  /* -- covid --*/
  "covid.restaurant.1.strong": "PLAN B: ",
  "covid.restaurant.1.text":
    "Prepare YOUR contingency plan now in the event of an increase in the number of cases - think about the possibilities of maneuver and alternatives you have in the event of the reintroduction of restrictions of various degrees, how you will reorganize work, what procedures you will introduce, how you will maintain contact with guests, manage financial resources and plan the work of people. You are richer for spring experiences. Dont be surprised a second time.",
  "covid.restaurant.2.strong": "SAFETY AND HYGIENE is already a standard, but...: ",
  "covid.restaurant.2.text":
    "Dont drop the topic. Check that all recommended procedures related to the safety of guests and employees work flawlessly - face masks on the waiters, disinfection of tables after each guest, electronic menu as an option for guests, procedures for washing and disinfecting hands, keeping the right distance between tables and making payments as easy as possible non-cash. Dont be afraid to politely instruct guests on how to behave in your restaurant.",
  "covid.restaurant.3.strong": "BUILD YOUR COMMUNITY: ",
  "covid.restaurant.3.text":
    "Our observations show that those restaurants that managed to build a community around themselves and during the lockdown maintained contact with their guests via social media or text messages sent from the MojStolik.pl application fared much better in the new reality than those that did not communicate in an appropriate way. So take care of constant, interesting and reliable contact with your guests now – take care of social media, website, and also think about how else you will be able to communicate if necessary. Fortunately, the government, during its work in May, withdrew from the obligation for each restaurant to have a database of its guests, but as a responsible restaurateur, you should build such a database. This is where the use of the MojStolik.pl platform is very helpful - it makes it as easy as possible to collect and store data (name, email, telephone number) of guests in accordance with the GDPR. The data belongs to the restaurant.",
  "covid.restaurant.4.strong": "RESERVATION NECESSARY? ",
  "covid.restaurant.4.text":
    "In many countries of Europe and the world there were ideas that you could go to a restaurant only after booking a table. So be prepared for such an eventuality and take care of the technology.",
  "covid.restaurant.5.strong": "TRY TO MAKE YOUR GUESTS AWARE – ",
  "covid.restaurant.5.text":
    "use social media, but also other channels, to communicate why it is so important to follow the safety rules in the restaurant and what procedures you have introduced to minimize the risk of infection. Also make them aware that some of their behavior exposes you to losses that you cannot afford at this difficult time. Its mostly unused bookings that cost you time and money. Some people just dont realize how big of a problem this is for restaurateurs. Thanks to the Mojstolik.pl platform, the reservation cancellation process becomes very simple for guests and is fully automatic on the restaurant side.",
  "covid.restaurant.6.strong": "LOOK FOR SAVINGS: ",
  "covid.restaurant.6.text":
    "Especially today, every guest is a treasure and lets not let him wait for a busy waiter to pick up the reservation, who with a limited staff has much more responsibilities and may not be able to reach the phone in time. Our calculations show that the reservation system saves more than 20 hours of waiter or host work per month, because about 50% of reservations automatically go from the phone to the Internet and burden your team. Pure savings!",
  "covid.restaurant.7.strong": "OPTIMIZE THE USE OF THE ROOM: ",
  "covid.restaurant.7.text":
    "In the event of reduced restaurant occupancy, optimizing the management of reservations and table utilization is a key business issue. Check out ready-made technological solutions that will facilitate control over bookings coming from all communication channels: website, application, social media, telephone, walk-in. Such a solution is the MojStolik.pl platform.",
  "covid.restaurant.8.strong": "MINIMIZING THE NON-SHOW EFFECT: ",
  "covid.restaurant.8.text":
    "A reservation that is not canceled but the guest does not show up is the bane of restaurateurs. When the occupancy of the premises is only allowed at the level of 55-75%, such behavior of guests has real financial consequences. Therefore, try to minimize the risk of such events. MojStolik.pl sends guests SMS reminders about the reservation. Its possible cancellation is a matter of clicking on the link sent. By using this solution, you increase the possibility to fully use the potential of your restaurant and avoid losses. In London, gastronomes have revolted because this is not the time to miss out on their opportunity to make money.",
  "covid.restaurant.9.strong": "SAFE MENU: ",
  "covid.restaurant.9.text":
    "It is recommended that menus in restaurants be disposable and thrown away every time it ends up in someones hands. Such restrictions have been introduced as an obligation, e.g. in some US states. It is neither economical nor ecological. Maybe its worth considering the option of presenting your menu on the guests smartphone? Users of MojStolik.pl already have this possibility. The waiter gains the opportunity to suggest guests who have downloaded the application and thus familiarized themselves with the menu. You can also send an SMS with a link from the application before the visit and ask guests to introduce themselves to the atmosphere of the evening by selecting dishes earlier.☺ The application can also be downloaded at the table via a QR code. A fast, modern and imaginative option for some guests. Its worth proposing.",
  "covid.restaurant.10.strong": "CUSTOMIZE THE KITCHEN: ",
  "covid.restaurant.10.text":
    "The menu should be quite short and mostly based on seasonal, local or easily available products. Other variants are a risk, unless you are looking for an additional menu - easy to replace. In the event of problems and new restrictions, e.g. with long distance travel or closing borders, you will have no problems with deliveries and obtaining products for the chef. Reorganizing the work of the kitchen from day to day will be much easier. Costs are also significant.",
  "covid.restaurant.11.strong": "DOWNLOAD RESPONSIBLE SUPPLIERS: ",
  "covid.restaurant.11.text":
    "Search for business partners. Companies that will help, will understand and give new solutions. Look for suppliers of high-quality products in the vicinity of your business - relying on long-distance deliveries and hard-to-find products is too risky these days. Information on what companies do for their restaurants/partners can be found on the portal www.fortalks.pl.",
  "covid.restaurant.header": "A GUIDE TO A RESPONSIBLE RESTAURANT",
  "covid.restaurant.title": "A GUIDE TO A RESPONSIBLE RESTAURANT",
  "covid.restaurant.text1":
    "We all know that restaurants in Poland are struggling to survive, which is why they mostly have high standards of work, hygiene and communication with guests. Lets go a step further. The MojStolik.pl platform and the FOR Solutions team have developed a system and a package of ready-made solutions in the event of new restrictions and a possible second wave of the pandemic. The aim of the guide is to help restaurants survive in business and to reduce the risk of coronavirus infections as much as possible.",
  "covid.restaurant.text2":
    "The first wave of coronavirus and the related lockdown was a real shock for the catering market. It took everyone some time to shake it off and move on to operating in the new reality. Now we are familiar with the situation, richer in knowledge and experience on how to deal with crisis situations. However, in their own and well-understood interest, together with their guests, restaurants must do everything to prevent the spread of the virus. We have made a summary of how to prepare your restaurant to work in the face of the growing number of infections:",
  "covid.restaurant.welcome1": "Dear Restaurateur",
  "covid.restaurant.welcome2":
    "Here are 11 solutions that will help keep your business going during these difficult times!",
  "covid.restaurant.summary":
    "We hope that this set of tips will be helpful in adapting your restaurant to the new reality and in preparing for possible difficulties. If you have additional questions, we are here to help. If you want more information about cooperation with us, fill out the form. We will call you back or write back.",
  "covid.restaurant.greetings": "We want to help, thats why we join forces!",
  "covid.restaurant.team": "Team MojStolik.pl and FOR Solutions.",
  "covid.client.1.strong": "BASIC HYGIENE PRINCIPLES – ",
  "covid.client.1.text":
    "that is, when you enter the premises, put on a mask and take it off, only when you sit down at the table, disinfect your hands before entering (restaurants are required to have a liquid dispenser at the entrance). When you go to the toilet, cover your mouth and nose with a mask again. These issues are really crucial to limiting the risk of contracting COVID-19. ",
  "covid.client.2.strong": "SOCIAL DISTANCE – ",
  "covid.client.2.text":
    "we know that keeping the right distance is troublesome and we often forget ourselves, but simple rules are enough: do not make life difficult for the team and do not change the location of tables without the consent of the waiter or manager. They have been set up to ensure your safety.",
  "covid.client.3.strong": "TABLE CHOICE – ",
  "covid.client.3.text":
    "when you enter a restaurant (wearing a face mask!), wait for the waiter to show you the right table. In this way, you will make it easier for him to work and seat guests safely. If you can choose a seat yourself, sit only at tables that are marked as disinfected.",
  "covid.client.4.strong": "RESERVATION – ",
  "covid.client.4.text":
    "if you know you are going to a restaurant, e.g. tonight, book a table in advance. This makes it easier for restaurant employees to plan the seating of guests, but also to plan supplies and staff work. In addition, it shortens the time of your contact with the staff as much as possible, which is beneficial for both you and them. You can easily and quickly book a table in a selected restaurant using the MojStolik.pl application. Why wait for a table when it can wait for you?",
  "covid.client.5.strong": "CANCEL YOUR BOOKING IF YOU CANT COME! ",
  "covid.client.5.text":
    "Not showing up at the restaurant despite having made a reservation is not only, to put it mildly, rude, but also exposes the restaurant owner to real losses. He cant assign your table to anyone, because its reserved. He took you and your fellow guests into account when planning supplies. Such a situation is a real financial loss for him, now that the occupancy of the restaurant is reduced anyway, it is especially severe. The MojStolik.pl platform will also make it easier for you - it will not only remind you by text message when and where the chef is waiting for you, but it will also make it as easy as possible for you to cancel your reservation if necessary. Its just a click on one link!",
  "covid.client.6.strong": "TOUCHLESS MENU – ",
  "covid.client.6.text":
    "if you have a table reservation application on your smartphone, it will also allow you to familiarize yourself with the menu without having to pick up the restaurant. If you do not have the application, download it using special QR codes that are available at the tables.",
  "covid.client.7.strong": "PAY CONTACTLESS – ",
  "covid.client.7.text":
    "avoid direct contact and cash payments. Pay with a credit card, blik or phone.",
  "covid.client.8.strong": "DONT CRITIQUE – ",
  "covid.client.8.text":
    "it is still a very difficult time for an industry that fights for survival almost every day. Therefore, refrain from expressing critical remarks in public, and focus on positive emotions related to feasting. We all need good emotions right now, especially restaurants that are trying twice to survive.",
  "covid.client.9.strong": "SUPPORT – ",
  "covid.client.9.text":
    "if you were satisfied with your visit, be sure to tell about it in your social media, make INSTA STORIES and have fun praising the dishes and attracting other guests to your favorite restaurant!",
  "covid.client.page.title": "A GUIDE FOR RESPONSIBLE GUESTS",
  "covid.client.dear.guest": "Dear Guest",
  "covid.client.description":
    "No restaurant is right without you being. Its all for you. if not you support the gastro business, it will disappear he from the streets of our cities. And admit that a world without bars and restaurants would be terribly sad and empty, because meetings at table connect, entertain and develop our lives sociable.",
  "covid.client.description2":
    "Therefore, we appeal - support your favorite restaurants, visit them, but do it with your head. In the era of coronavirus, our common social responsibility is extremely important. Also, pay attention to some of your behaviors that cause real losses in restaurants. We give examples of them to help restaurants run more efficiently. And finally, remember all the safety rules to help restaurants maintain high standards of work, hygiene and organization. They care about it! Take care and YOU!",
  "covid.client.description3":
    "So how to responsibly enjoy time in your favorite restaurants? MojStolik.pl and FOR Solutions have prepared some tips for you:",
  "covid.client.summary":
    "By following these simple rules, you will take care of yourself your safety and that of your favorite crew restaurant, and being there and enjoying them delicious dishes, you really support her and you help me get through this difficult time. Image of ours cities without restaurants would be tragic…",
  /* -- global -- */
  "global.messages.sessionTimeout": "Session has expired",
  "global.form.message.requiredField": "Required field",
  "global.form.message.vatIDNumber": "VAT ID number should contain 10 digits",
  "global.form.message.invalidEmailFormat": "Invalid e-mail address format",
  "global.form.message.invalidPhoneFormat": "Invalid phone number format",
  "global.form.message.invalidBirthDateFormat": "Invalid date of birth format",
  "global.form.message.invalidPostCodeFormat": "Invalid postal code format. Expected XX-XXX",
  "global.form.message.invalidNipFormat": "Invalid NIP number format",
  "global.form.message.invalidKrsFormat": "Invalid KRS number format",
  "global.form.message.invalidRegonFormat": "Invalid REGON number format",
  "global.form.message.invalidUrlFormat": "Invalid URL format",
  "global.form.message.newPasswordsDoNotMatch": "New passwords do not match!",
  "global.form.message.atLeastNineCharacters": "At least nine characters required",
  "global.time.minutes": "minutes",
  /* -- metadata -- */
  "aboutPage.metadata.title": "About us",
  "aboutPage.metadata.description": "About us description",
  "aboutPage.metadata.keywords": "About us keywords",
  "accountPage.metadata.title": "Your account",
  "accountPage.metadata.description": "Your account description",
  "accountPage.metadata.keywords": "Your account keywords",
  "contactPage.metadata.title": "Contact",
  "contactPage.metadata.description": "Contact description",
  "contactPage.metadata.keywords": "Contact keywords",
  "documentsPage.metadata.title": "Documents",
  "documentsPage.metadata.description": "Documents description",
  "documentsPage.metadata.keywords": "Documents keywords",
  "forGuestPage.metadata.title": "For guest",
  "forGuestPage.metadata.description": "For guest description",
  "forGuestPage.metadata.keywords": "For guest keywords",
  "howItWorksPage.metadata.title": "How it works?",
  "howItWorksPage.metadata.description": "How it works description",
  "howItWorksPage.metadata.keywords": "How it works keywords",
  "loginPage.metadata.title": "Log in",
  "loginPage.metadata.description": "Log in description",
  "loginPage.metadata.keywords": "Log in keywords",
  "resetPasswordPage.metadata.title": "Password reset",
  "resetPasswordPage.metadata.description": "Password reset description",
  "resetPasswordPage.metadata.keywords": "Password reset keywords",
  "logoutPage.metadata.title": "Log out",
  "logoutPage.metadata.description": "Log out description",
  "logoutPage.metadata.keywords": "Log out keywords",
  "notFoundPage.metadata.title": "404 - Not found page!",
  "notFoundPage.metadata.description": "404 not found description",
  "notFoundPage.metadata.keywords": "",
  "priceListPage.metadata.title": "Price list",
  "priceListPage.metadata.description": "Price list description",
  "priceListPage.metadata.keywords": "Price list keywords",
  "registrationPage.metadata.title": "Registration",
  "registrationPage.metadata.description": "Registration description",
  "registrationPage.metadata.keywords": "Registration keywords",
  "reservationHistoryPage.metadata.title": "Your reservations",
  "reservationHistoryPage.metadata.description": "Your reservations description",
  "reservationHistoryPage.metadata.keywords": "Your reservations keywords",
  "searchMapPage.metadata.title": "Search results map",
  "searchMapPage.metadata.description": "Search results map description",
  "searchMapPage.metadata.keywords": "Search results map keywords",
  "searchPage.metadata.title": "Search results",
  "searchPage.metadata.description": "Search results description",
  "searchPage.metadata.keywords": "Search results keywords",
  "privacyPolicyPage.metadata.title": "Privacy policy",
  "privacyPolicyPage.metadata.description": "",
  "privacyPolicyPage.metadata.keywords": "",
  "cookiesPolicyPage.metadata.title": "Cookies policy",
  "cookiesPolicyPage.metadata.description": "",
  "cookiesPolicyPage.metadata.keywords": "",
  /* -- shortcuts -- */
  "shortcut.isClosed": "closed",
  "shortcut.monday": "Mo.",
  "shortcut.tuesday": "Tu.",
  "shortcut.wednesday": "We.",
  "shortcut.thursday": "Th.",
  "shortcut.friday": "Fr.",
  "shortcut.saturday": "Sa.",
  "shortcut.sunday": "Su.",
  /* -- default values -- */
  "defaultValues.restaurant": "Restaurant",
  /* -- restaurantPromotionInfoModal component -- */
  "restaurantPromotionInfoModal.button.use": "Use",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Offer details",
  /* -- previewImageModal component -- */
  "previewImageModal.component.anchor.close": "Close",
  /* -- previewRestaurantModal component -- */
  "previewRestaurantModal.component.anchor.close": "Close",
  /* -- deleteAccountModal component -- */
  "deleteAccountModal.component.header": "Delete account",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Are you sure you want to delete your account? Your booking history will also be deleted.",
  "deleteAccountModal.component.button.confirm": "Confirm",
  "deleteAccountModal.component.button.cancel": "Cancel",
  /* -- cancellationReservationModal component -- */
  "cancellationReservationModal.component.header": "Cancellation of reservation",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Are you sure you want to cancel your table reservation at {{restaurantName}} at {{reservationHour}} on {{reservationDate}}?",
  "cancellationReservationModal.component.button.confirm": "Confirm",
  "cancellationReservationModal.component.button.cancel": "Cancel",
  /* -- searchResultItem component -- */
  "searchResultItem.component.label.availableHours": "Available hours",
  "searchResultItem.component.label.futureReservationDaysError":
    "Remote bookings are available for {{days}} days ahead",
  "searchResultItem.component.label.noAvailableTables":
    "For selected search parameters (date, time, number of person) this restaurant does not offer tables for booking.",
  "searchResultItem.component.button.book": "Book now",
  "searchResultItem.component.button.cancel": "Cancel",
  "searchResultItem.component.button.see": "See more",
  /* -- passwordFormModal component -- */
  "passwordFormModal.component.header": "Enter the account password",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Enter your password to confirm the action.",
  "passwordFormModal.component.button.confirm": "Confirm",
  "passwordFormModal.component.button.cancel": "Cancel",
  /* -- cuisine types -- */
  "cuisine.romania": "Romanian cuisine",
  "cuisine.american": "American cuisine",
  "cuisine.arabic": "Arabic cuisine",
  "cuisine.chinese": "Chinese cuisine",
  "cuisine.polish": "Polish cuisine",
  "cusine.chinese": "Chinese cuisine",
  "cusine.polish": "Polish cuisine",
  /* -- cookies policy line -- */
  "component.cookiesPolicyLine.text":
    "This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use.",
  "component.cookiesPolicyLine.privacyPolicyText": "Privacy policy",
  /* -- header -- */
  "component.header.menu.mainPage": "Book a table",
  "component.header.menu.forRestaurants": "For restaurants",
  "component.header.menu.portal.restaurant": "Restaurant portal",
  "component.header.menu.forRestaurants.offer": "Our offer",
  "component.header.menu.blog": "News from MOJSTOLIK.PL",
  "component.header.menu.christmas": "Christmas",
  "component.header.menu.forGuest": "For visitor",
  "component.header.menu.howItWorks": "How it works?",
  "component.header.menu.priceList": "Price list",
  "component.header.menu.contact": "Contact",
  "component.header.menu.documents": "Documents",
  "component.header.menu.about": "About",
  "component.header.menu.login": "Log in",
  "component.header.menu.logout": "Log out",
  "component.header.menu.reservationHistory": "Your reservations",
  "component.header.menu.yourAccount": "Your account",
  "component.header.menu.covid": "COVID-19 guides",
  "component.header.menu.covid.restaurant": "Guide for restaurants",
  "component.header.menu.covid.client": "Guest guide",
  "component.header.menuMobile.covid.restaurant": "COVID-19 guide for restaurants",
  "component.header.menuMobile.covid.client": "COVID-19 guide for guests",
  /* -- footer -- */
  "component.footer.menu.header.forClients": "ABOUT MOJSTOLIK.PL",
  "component.footer.menu.header.informations": "Informations",
  "component.footer.menu.header.yourAccount": "Your account",
  "component.footer.menu.header.socialMedia": "Social media",
  "component.footer.menu.header.applicationDownload": "Download",
  "component.header.menu.events": "Events",
  "component.footer.menu.forRestaurants": "For restaurants",
  "component.footer.menu.forGuest": "For visitor",
  "component.footer.menu.howItWorks": "How it works?",
  "component.footer.menu.priceList": "Price list",
  "component.footer.menu.contact": "Contact",
  "component.footer.menu.documents": "Documents",
  "component.footer.menu.about": "About",
  "component.footer.menu.login": "Log in",
  "component.footer.menu.reservationHistory": "Your reservations",
  "component.footer.menu.yourAccount": "Your account",
  "component.footer.menu.logout": "Log out",
  "component.footer.menu.registration": "Registration",
  "component.footer.menu.privacyPolicy": "Privacy policy",
  "component.footer.menu.cookiesPolicy": "Cookies policy",
  /* -- index page -- */
  "indexPage.background.header": "Find the best restaurants in your city",
  "indexPage.header.currentPromotions": "Special offers and events",
  "indexPage.header.currentPromotionsExtend": "in your location",
  "indexPage.header.mostPopular": "Most popular",
  "indexPage.header.mostPopularExtend": "in your city",
  "indexPage.background.headerExtend": "and then book a table:",
  "indexPage.input.label.location": "Location",
  "indexPage.input.label.date": "Date",
  "indexPage.input.label.hour": "Hour",
  "indexPage.input.label.persons": "Number of person",
  "indexPage.input.label.persons.optionExtend": "p.",
  "indexPage.input.label.restaurantType": "Cuisine type",
  "indexPage.input.label.rating": "Rating",
  "indexPage.input.label.rating.optionExtend": "star(-s)",
  "indexPage.input.label.priceLevel": "Price level",
  "indexPage.input.label.searchByName": "Search by name",
  "indexPage.input.placeholder.select": "Select",
  "indexPage.input.placeholder.type": "Type in",
  "indexPage.input.placeholer.typeInOrSelect": "Type in or select",
  "indexPage.input.noData": "No results.",
  "indexPage.button.search": "Search",
  "indexPage.button.showAdditionalFilters": "Additional filters",
  "indexPage.button.hideAdditionalFilters": "Hide additional filters",
  "indexPage.button.use": "Use",
  "indexPage.button.promotion": "discount",
  "indexPage.button.bookNow": "Book now",
  /* -- account page -- */
  "accountPage.header": "Your account",
  "accountPage.subheader":
    "Change your personal data so that the process of your implementation is faster.",
  "accountPage.headerInColumn.yourPersonalData": "Your personal data",
  "accountPage.headerInColumn.yourPassword": "Your password",
  "accountPage.input.label.firstName": "First name",
  "accountPage.input.placeholder.firstName": "E.g. George",
  "accountPage.input.label.lastName": "Last name",
  "accountPage.input.placeholder.lastName": "E.g. Jones",
  "accountPage.input.label.phoneNumber": "Phone number",
  "accountPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "accountPage.input.label.email": "E-mail address",
  "accountPage.input.placeholder.email": "E.g. address@email.com",
  "accountPage.paragraph.optionalFields": "Optional fields",
  "accountPage.input.label.birthDate": "Date of birth",
  "accountPage.input.placeholder.birthDate": "E.g. 1976-10-25",
  "accountPage.input.label.postalCode": "Post code",
  "accountPage.input.placeholder.postalCode": "E.g. 03-123",
  "accountPage.input.label.city": "City",
  "accountPage.input.placeholder.city": "E.g. Warsaw",
  "accountPage.input.label.streetAndLocalNumber": "Street and local number",
  "accountPage.input.placeholder.streetAndLocalNumber": "E.g. Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Methods of communication:",
  "accountPage.button.save": "Save",
  "accountPage.button.deleteAccount": "Delete account",
  "accountPage.input.label.oldPassword": "Old password",
  "accountPage.input.label.newPassword": "New password",
  "accountPage.input.label.newPasswordRepeat": "New password (repeat)",
  "accountPage.button.changePassword": "Change password",
  "accountPage.message.personalDataUpdated": "Personal details have been updated.",
  "accountPage.message.accountPermanentlyDeleted": "Your account has been permanently deleted.",
  "accountPage.message.passwordChanged": "Password has been changed.",
  "accountPage.label.changePasswordNotPossible":
    "Changing the password is not possible because you logged in via Facebook or Google.",
  "accountPage.toast.error.disableBothChannels":
    "At least one communication method (SMS, EMAIL) must remain active!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Enabling the communication method requires confirmation of data accuracy. Save the changes to receive a verification link at the new email address!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Enabling the communication method requires confirmation of data accuracy. Save the changes to receive a verification SMS link to your new phone number!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "To enable the new communication method, confirm your data by clicking the activation link sent to your email address!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "To enable the new communication method, confirm your data by clicking the activation link sent via SMS to the provided phone number!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "To verify your data, click the verification link sent to your email address!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "To verify your data, click the verification link sent via SMS to your phone number!",
  "accountPage.channels.disclaimer":
    "* To edit the phone number or email address, you need to disable the communication module being edited.",
  "accountPage.confirmSms.header": "Data authenticity confirmation",
  "accountPage.confirmSms.paragraph":
    "To confirm your data, enter the code sent by SMS to the provided phone number!",
  /* -- reservation history page -- */
  "reservationHistoryPage.header": "Your reservations",
  "reservationHistoryPage.subheader":
    "Here you can view your historical reservations and manage future reservations.",
  "reservationHistoryPage.paragraph.personsShortcut": "p.",
  "reservationHistoryPage.button.revoke": "Revoke",
  "reservationHistoryPage.button.pay": "Pay",
  "reservationHistoryPage.paragraph.additionalDescription": "Communication with client",
  "reservationHistoryPage.paragraph.date": "Date",
  "reservationHistoryPage.paragraph.duration": "Duration",
  "reservationHistoryPage.paragraph.hour": "Hour",
  "reservationHistoryPage.paragraph.persons": "Number of person",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "The reservation does not exist",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "The reservation list is empty.",
  "reservationHistoryPage.paragraph.status": "Status",
  "reservationHistoryPage.paragraph.invoice": "Invoice",
  "reservationPage.paragraph.lateInfo":
    "Reminder, in case of being late the table awaits the Guest for 15 minutes only!",
  "reservationHistoryPage.paragraph.payment.status": "Payment status",
  /* -- search page -- */
  "searchPage.header": "Search results",
  "searchPage.button.map": "Map",
  "searchPage.button.list": "List",
  "searchPage.paragraph.noData": "No results for the given criteria.",
  /* -- search map page -- */
  "searchMapPage.map.button.refresh": "Refresh map",
  "searchMapPage.header": "Search results",
  "searchMapPage.button.map": "Map",
  "searchMapPage.button.list": "List",
  /* -- login page -- */
  "loginPage.header": "Log in",
  "loginPage.subheader":
    "As a logged in user you can reserve tables faster and view your booking history.",
  "loginPage.columns.header.standardLogin": "Standard log in",
  "loginPage.columns.header.socialLogin": "Log in with",
  "loginPage.input.label.phoneOrEmail": "Phone number/email address",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.input.label.password": "Password",
  "loginPage.input.placeholder.password": "",
  "loginPage.anchor.passwordReset": "Password reset",
  "loginPage.button.logIn": "Log in",
  "loginPage.paragraph.dontHaveAccountYet": "You dont have an account yet?",
  "modal.login.dontHaveAccountYet": "You dont have an account yet",
  "loginPage.paragraph.registerNow": "Register now!",
  "loginPage.button.register": "Register now",
  "loginPage.message.logged": "Logged.",
  "loginPage.message.loggedViaFacebook": "Logged in with Facebook.",
  "loginPage.message.loggedViaApple": "Logged in with Apple.",
  "loginPage.message.loggedViaGoogle": "Logged in with Google.",
  "loginPage.paragraph.alreadyLoggedIn": "You are already logged in.",
  /* -- Reset password page -- */
  "resetPasswordPage.header": "Password reset",
  "resetPasswordPage.subheader":
    "Fill out the form below, and we will send you a new password to your account via SMS or email.",
  "resetPasswordPage.subheader2": "We will send you a new password to your phone number",
  "resetPasswordPage.input.label.phoneOrEmail": "Enter your phone number or email address",
  "resetPasswordPage.input.placeholder.phoneOrEmail": "",
  "resetPasswordPage.button.confirm": "Confirm",
  "resetPasswordPage.toast.passwordChanged":
    "The new password has been sent to the provided phone number/email address.",
  "resetPasswordPage.toast.error.passwordChanged": "The provided phone number/email address is incorrect.",
  /* -- for restaurants page -- */
  "forRestaurantsPage.header": "For restaurants",
  "forRestaurantsPage.subheader": "The offer is directed to the restaurant.",

  /* --forRestaurants page 2.0 - global-- */
  "forRestaurantsPage.global.perMonth": "PLN/month",
  "forRestaurantsPage.global.perMonth.short": "PLN/mth.",
  "forRestaurantsPage.global.button.choose": "CHOOSE",
  "forRestaurantsPage.global.button.next": "NEXT",
  "forRestaurantsPage.global.button.back": "BACK",
  "forRestaurantsPage.global.button.accept": "ACCEPT",
  "forRestaurantsPage.global.button.discover": "DISCOVER AVAILABLE PLANS",
  "forRestaurantsPage.global.button.send": "SEND",
  "forRestaurantsPage.global.button.learnMore": "Learn more",
  /* --forRestaurants page 2.0-- */
  "forRestaurantsPage.apiError":
    "We are experiencing some technical issues. Please try again later.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.PL IS MUCH MORE THAN A SYSTEM",
  "forRestaurantsPage.1.subsection.1.header": "PORTAL & APP FOR GUESTS",
  "forRestaurantsPage.1.subsection.1.text":
    "A simple and convenient platform and app that enables fast table reservations in your restaurant.",
  "forRestaurantsPage.1.subsection.2.header": "TELEPHONE SYSTEM (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "The IVR system will take reservations instead of your team, who can dedicate themselves to serving your guests in the restaurant.",
  "forRestaurantsPage.1.subsection.3.header": "PORTAL & APP FOR RESTAURANTS",
  "forRestaurantsPage.1.subsection.3.text":
    "Manage Your tables and reservations automatically or manually from our tablet app or from Your restaurant's website.",
  "forRestaurantsPage.2.header": "MANAGE FROM THE APPLICATION OR BROWSER",
  "forRestaurantsPage.2.text":
    "Use advanced app features on tablet and browser interface. Forget about the reservation book and switch to a universal solution for any type of restaurant.",
  "forRestaurantsPage.3.header": "FORGET ABOUT NO-SHOW",
  "forRestaurantsPage.3.text":
    "With the MOJSTOLIK.PL system, you minimize the risk of empty tables where guests should be sitting thanks to:",
  "forRestaurantsPage.3.listItem.1": "SMS confirmation and reminders of the reservation",
  "forRestaurantsPage.3.listItem.2": "The ability for quick and easy cancellation of reservations",
  "forRestaurantsPage.3.listItem.3": "Analysis of guests' historical activity.",
  "forRestaurantsPage.4.header": "EFFECTIVELY DIRECTING TRAFFIC TO THE RESTAURANT",
  "forRestaurantsPage.5.header": "WHAT ARE RESTAURANT OWNERS SAYING ABOUT US?",
  "forRestaurantsPage.5.opinion.1.title": "Mariusz Diakowski, owner of the Stary Dom restaurant:",
  "forRestaurantsPage.5.opinion.1.text":
    'Working with MojStolik.pl was a true revolution for Stary Dom. Neither I nor the restaurant team can imagine going back to the "book and pencil" era. MojStolik.pl actually conducted tests of its platform at our restaurant. We were a bit apprehensive, but we decided to take the risk. We do not regret it at all! All of our comments and suggestions were immediately implemented. Thanks to testing "on a live organism," this system really meets the needs of restaurants with high traffic, it makes work incredibly easier and shorter. No more pens, pencils, correcting fluid sticking pages, illegible handwriting, and impractical books. Now everything is organized and easy to use. I also want to add that the platform is practically flawless, and if there is a need, the helpdesk operates without a hitch.',
  "forRestaurantsPage.5.opinion.2.title": "Marek Katrycz, COO at STIXX Bar&Grill",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl is a very modern and innovative system that allows us, as a large restaurant, to effectively manage guest reservations and seating arrangements, which simplifies the work of our team. We highly recommend this system, they are constantly improving its functionality, it is intuitive and very easy to use. It has reduced the time it takes to accept reservations and find them, which is particularly helpful during high occupancy",
  "forRestaurantsPage.5.opinion.3.title": "Robert Goluba, Brand Manager at Bombaj Masala:",
  "forRestaurantsPage.5.opinion.3.text":
    "Why did we decide on the MojStolik.pl system in our restaurants? Good reputation in the industry and a really pleasant and professional approach to new customers. The system is very intuitive, but the training for the staff took as long as it took for each restaurant employee to be able to handle the panel, so there were no problems with implementing MojStolik in the restaurant. It went without any problems, which is probably unprecedented for new software. The first day with a large number of guests and reservations in our restaurant was enough to convince us of the advantages of solutions in the MojStolik.pl system. I am sure that it allowed us (and still does) to smoothly receive guests and, as a result, to serve more guests. This naturally translates into financial results. If after a few weeks someone ordered me to return to work with a traditional reservation book, I would feel like... in the 20th century",
  "forRestaurantsPage.6.header": "DISCOVER THE PLANS OF MOJSTOLIK.PL",
  "forRestaurantsPage.6.subheader": "Choose a plan for your restaurant and start working with us.",
  "forRestaurantsPage.discountCodeInput": "Enter discount code",
  "forRestaurantsPage.discountCodeButton": "Redeem",
  "forRestaurantsPage.discountCodeLoading": "Loading...",
  "forRestaurantsPage.discountCodeSuccess": "Discount code applied",
  "error.promotion.not.exists": "Discount code does not exist",
  "forRestaurantsPage.allOffers": "To learn about a detailed comparison of MOJSTOLIK.PL plans",
  "forRestaurantsPage.allOffers.orange": "click here",
  "forRestaurantsPage.form.title":
    "If you are unsure and would like to learn more about the MojStolik.pl platform, please fill out this short form and we will contact you:",
  /* --forRestaurants page 2.0 - komponent ofert-- */
  "forRestaurantsPage.offers.offerItem.1.header": "LIGHT",
  "forRestaurantsPage.offers.offerItem.1.text":
    "For restaurants that value the simplicity of automating the reservation collection process in real-time.",
  "forRestaurantsPage.offers.offerItem.2.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.2.text":
    "For restaurants that not only want to manage reservations, but also want to manage rooms and tables.",
  "forRestaurantsPage.offers.offerItem.3.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "For large restaurants where full automation of all communication channels with guests is essential.",
  "forRestaurantsPage.offers.subscription": "Subscription",
  "forRestaurantsPage.offers.commission": "Commission",
  "forRestaurantsPage.offers.commission.currency": "PLN",
  "forRestaurantsPage.offers.header": "Additional options",
  "forRestaurantsPage.offers.additional-options.prepayments": "Prepayment module for reservations",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Automatic handling of phone reservations (IVR) with an additional 300 SMS/month package",
  "forRestaurantsPage.offers.additional-options.smsPackage":
    "Additional SMS message package - 1000 pieces/month",
  "forRestaurantsPage.offers.mostPopular": "Most popular choice",
  "forRestaurantsPage.offers.pricingSetting1":
    "The above prices apply to the annual billing period. Switch to monthly billing: ",
  "forRestaurantsPage.offers.pricingSetting2":
    "The above prices apply to the monthly billing period. Switch to annual billing: ",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1. All prices in the offer are net prices in PLN and the legally required VAT tax will be added at the rate applicable on the invoice date.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2. In case of the annual payment model, the amount of the monthly fee is presented, however, the payment is made in a one-time lump sum for 12 months in advance.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3. A marketing discount of 50 PLN net is granted based on the monthly fee, provided that the restaurant shares the reservation link via MOJSTOLIK.PL on the restaurant's social media at least twice a month.",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4. The cost of an SMS outside of the subscription is 0.09 PLN net.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5. In case of using the Prepaid Module, a fee of 1.59% of the transaction amount is charged by the payment operator for each transaction.",
  /* --forRestaurants page 2.0 - porównanie ofert-- */
  "forRestaurantsPageAllOffers.header": "Discover the plans of MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Choose a plan for your restaurant and start working with us.",
  "forRestaurantsPageAllOffers.offer.1": "LIGHT",
  "forRestaurantsPageAllOffers.offer.2": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Reservation channels available for customers",
  "forRestaurantsPageAllOffers.listHeader.2": "Restaurant reservation management",
  "forRestaurantsPageAllOffers.listHeader.3": "Restaurant profile configuration",
  "forRestaurantsPageAllOffers.listHeader.4": " Accessibility for restaurants from devices",
  "forRestaurantsPageAllOffers.listHeader.5": "Additional services",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1":
    "Reservations from the mojstolik.pl website",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Reservations from the dedicated MojStolik mobile application",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3":
    "Reservations from the restaurant's website",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Reservations from Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Reservations from Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Confirmations  /reminders of reservations through SMS/app notifications**",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
    "Automatic handling of telephone reservations",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
    "Reservations from the restaurant's Instagram",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Reservations from email inbox (autoresponder)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Links / QR codes in restaurant PR articles and materials",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Reservation list - tabular view",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Reservations on room map",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Gantt view (reservation schedule)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4": "Reservation details and editing",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Advanced reservation management",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Daily reservation report",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7": "Pre-payment handling for reservations",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Walk-in handling",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Reservation history/analysis of guest preferences and behavior",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": "Minimization of no-show effect",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Working in live mode",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "Restaurant customer database/compliance with GDPR",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "DATA SECURITY / Encrypted system and different access levels based on roles",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Restaurant's business card and individual profile on customer portal and mobile app",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Restaurant descriptive data",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Graphic materials",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Availability hours management",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Breaks and special days",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6": "Reservation acceptance parameterization",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Room and table configuration",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Management portal for restaurant",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Restaurant tablet app",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1": "Help desk and technical support",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2": "Onboarding training for restaurant staff",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Presence/promotion of restaurant on MojStolik.pl social media channels",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Number of SMS messages available in subscription",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/mth",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/mth",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/mth",
  "forRestaurantsPageAllOffers.listItem.optional": "Option",
  /* --forRestaurants page 2.0 - formularz-- */
  "forRestaurantsPageForm.downloadButton": "DOWNLOAD DATA",
  "forRestaurantsPageForm.header": "This is where you start your collaboration with MOJSTOLIK.PL",
  "forRestaurantPageForm.apiError":
    "Failed to download offer data. Sorry for the inconvenience, please try again later.",
  "forRestaurantPageForm.paramsError":
    "Sorry, we couldn't get the offer details. To see our offers please visit",
  "forRestaurantPageForm.paramsError.link": "this site",
  "forRestaurantsPageForm.chosenPlan": "Selected MOJSTOLIK.PL plan",
  "forRestaurantsPageForm.discountCode": "Discount code: ",
  "forRestaurantsPageForm.payment.yearly": "The selected plan is billed with a yearly period.",
  "forRestaurantsPageForm.payment.monthly": "The selected plan is billed with a monthly period.",
  "forRestaurantsPageForm.additionalOptions": "Additional options:",
  "forRestaurantsPageForm.formHeader.1": "Provide basic restaurant information",
  "forRestaurantsPageForm.formHeader.2": "Provide contact person information",
  "forRestaurantsPageForm.formHeader.3": "Provide Contract data",
  "forRestaurantsPageForm.formHeader.4": "Provide representative data",
  "forRestaurantsPageForm.formHeader.5": "MOJSTOLIK.PL Contract",
  "forRestaurantsPageForm.restaurantName": "Restaurant name",
  "forRestaurantsPageForm.street": "Street",
  "forRestaurantsPageForm.apartment": "Local",
  "forRestaurantsPageForm.postalCode": "Postal code",
  "forRestaurantsPageForm.city": "City",
  "forRestaurantsPageForm.email": "Email address",
  "forRestaurantsPageForm.phoneNumber": "Phone number",
  "forRestaurantsPageForm.website": "Website address",
  "forRestaurantsPageForm.website.menu": "Restaurant menu website address",
  "forRestaurantsPageForm.name": "Name",
  "forRestaurantsPageForm.surname": "Surname",
  "forRestaurantsPageForm.title": "Title",
  "forRestaurantsPageForm.entity": "Entity name",
  "forRestaurantsPageForm.email.invoice": "Invoice email address",
  "forRestaurantsPageForm.email.contact": "Contact email address",
  "forRestaurantsPageForm.proxy": "Type of power of attorney",
  "forRestaurantsPageForm.select.owner": "Owner",
  "forRestaurantsPageForm.select.manager": "Manager",
  "forRestaurantsPageForm.select.boardMember": "Board Member",
  "forRestaurantsPageForm.select.other": "Other",
  "forRestaurantsPageForm.contract": "Contract content",
  "forRestaurantsPageForm.pdfLink": "Download pdf",
  "forRestaurantsPageForm.statement.1":
    "I agree to the processing of my personal data by Table4Rest Ltd. for the purpose of entering into a contract for the provision of services for access to and use of the MojStolik.pl application.",
  "forRestaurantsPageForm.statement.2":
    "I accept the above contract conditions and the MOJSTOLIK.PL regulations and I declare that I am authorized to enter into a contract for the provision of services for access to and use of the MojStolik.pl application on behalf of the restaurant.",
  "forRestaurantsPageForm.loading": "Your form is being submitted...",
  "forRestaurantsPageForm.success": "Form submission successful.",
  "forRestaurantsPageForm.nipError": "Enter correct VAT number",
  "forRestaurantsPageForm.loadingData": "Data download in progress...",
  "forRestaurantsPageForm.error": "Error occurred.",
  "forRestaurantsPageForm.dataSuccess": "Data retrieved successfully",
  "forRestaurantsPageForm.generatingContract": "Contract is being generated...",
  /* --forRestaurants page 2.0 - sukces-- */
  "forRestaurantsPageSuccess.header": "Thank you for choosing the offer from Mojstolik.pl",
  "forRestaurantsPageSuccess.text.1":
    "IN A MOMENT, A DOCUMENT OF THE AGREEMENT WHICH WAS ACCEPTED IN THE PREVIOUS STEP WILL BE SENT TO THE E-MAIL ADDRESS PROVIDED.",
  "forRestaurantsPageSuccess.text.2":
    "At the same time, we will prepare and provide you with the necessary instructions that will allow you to configure your restaurant in the system and start cooperation.",
  "forRestaurantsPageSuccess.text.3": "See you!",
  "forRestaurantsPageSuccess.button": "Return to the main page",

  /* -- cookies policy page -- */
  "cookiesPolicyPage.header": "Cookies policy",
  "cookiesPolicyPage.subheader": "Important information.",
  "cookiesPolicyPage.point.1":
    "The website does not automatically collect any information, except for information contained in cookie files.",
  "cookiesPolicyPage.point.2":
    'Cookie files (so-called "cookies") are IT data, in particular text files, which are stored in the Website Users end device and are intended for using the Websites websites. Cookies usually contain the name of the website they come from, their storage time on the end device and a unique number.',
  "cookiesPolicyPage.point.3":
    "The entity placing cookies on the Website Users end device and accessing them is the owner of mojstolik.pl, Table4Rest Sp. z o. o. with its registered office at ul. Ksawerów 21, 02-656 Warsaw.",
  "cookiesPolicyPage.point.4": "Cookies are used to:",
  "cookiesPolicyPage.point.4a":
    "adapting the content of the Website pages to the Users preferences and optimizing the use of websites; in particular, these files allow to recognize the Website Users device and properly display the website, tailored to his individual needs;",
  "cookiesPolicyPage.point.4b":
    "creating statistics that help to understand how Website Users use websites, which allows improving their structure and content;",
  "cookiesPolicyPage.point.4c":
    "maintaining the Website Users session (after logging in), thanks to which the User does not have to re-enter the login and password on each subpage of the Website;",
  "cookiesPolicyPage.point.5":
    'The Website uses two basic types of cookies: "session" (session cookies) and "permanent" (persistent cookies). Session cookies are temporary files that are stored on the Users end device until logging out, leaving the website or turning off the software (web browser). "Permanent" cookies are stored on the Users end device for the time specified in the cookie file parameters or until they are deleted by the User.',
  "cookiesPolicyPage.point.6": "The following types of cookies are used on the Website:",
  "cookiesPolicyPage.point.6a":
    '"necessary" cookies, enabling the use of services available on the Website, e.g. authentication cookies used for services requiring authentication on the Website;',
  "cookiesPolicyPage.point.6b":
    "cookies used to ensure security, e.g. used to detect fraud in the field of authentication within the Website;",
  "cookiesPolicyPage.point.6c":
    "performance cookies, enabling the collection of information on how the Website pages are used;",
  "cookiesPolicyPage.point.6d":
    '"functional" cookies, enabling "remembering" the settings selected by the User and personalizing the Users interface, e.g. in terms of the selected language or region from which the User comes from, font size, website appearance, etc.;',
  "cookiesPolicyPage.point.6e":
    '"advertising" cookies, enabling the provision of advertising content to Users more tailored to their interests.',
  "cookiesPolicyPage.point.7":
    "In many cases, the software used for browsing websites (web browser) allows cookies to be stored on the Users end device by default. Website users may change their cookie settings at any time. These settings can be changed in particular in such a way as to block the automatic handling of cookies in the web browser settings or to inform about each time they are placed on the Website Users device. Detailed information on the possibilities and ways of handling cookies are available in the software (web browser) settings.",
  "cookiesPolicyPage.point.8":
    "The Website Operator informs that restrictions on the use of cookies may affect some of the functionalities available on the Websites websites.",
  /* -- privacy policy page -- */
  "privacyPolicyPage.header": "Privacy policy",
  "privacyPolicyPage.subheader": "Important information.",
  "privacyPolicyPage.point.one": "GENERAL PROVISIONS",
  "privacyPolicyPage.point.one.1":
    "The data administrator is Table4Rest Sp. z o. o. with its registered office in Warsaw 02-656, ul. Ksawerów 21, entered into the Register of Entrepreneurs under the KRS number: 0000752110, for which the registration files are kept by the DISTRICT COURT FOR THE CITY OF WARSAW IN WARSAW, 13th COMMERCIAL DEPARTMENT OF THE NATIONAL COURT REGISTER NIP: 5213842139 REGON: 381529521",
  "privacyPolicyPage.point.one.2":
    "For the interpretation of terms, the glossary of the Regulations is used or as described in the Privacy Policy (if it results directly from the description).",
  "privacyPolicyPage.point.one.3":
    'For the purposes of better reception of the Privacy Policy, the term "User" has been replaced with the term "You", "Administrator" - "We". The term "GDPR" means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.',
  "privacyPolicyPage.point.one.4":
    "We respect the right to privacy and we care about data security. For this purpose, e.g. secure communication encryption protocol (SSL).",
  "privacyPolicyPage.point.one.5":
    "Personal data provided in the form on the landing page are treated as confidential and are not visible to unauthorized persons.",
  "privacyPolicyPage.point.two": "DATA ADMINISTRATOR",
  "privacyPolicyPage.point.two.1":
    "The service provider is the administrator of people who have used the contact form on the website or used other forms of communication (e-mail, telephone, mobile application, plug-in order a conversation, other forms of communication).",
  "privacyPolicyPage.point.two.2": "Personal data is processed:",
  "privacyPolicyPage.point.two.2a":
    "a. in accordance with the provisions on the protection of personal data,",
  "privacyPolicyPage.point.two.2b": "b. in accordance with the implemented Privacy Policy,",
  "privacyPolicyPage.point.two.2c":
    "c. to the extent and for the purpose necessary to establish, shape the content of the Agreement, change or terminate it and the proper implementation of the Services provided,",
  "privacyPolicyPage.point.two.2d":
    "d. to the extent and purpose necessary to fulfill legitimate interests (legitimate purposes), and the processing does not violate the rights and freedoms of the data subject.",
  "privacyPolicyPage.point.two.3":
    "Each data subject (if we are their administrator) has the right to access data, rectify, delete or limit processing, the right to object, the right to lodge a complaint with the supervisory authority.",
  "privacyPolicyPage.point.two.4":
    "Contact with the person supervising the processing of personal data in the Service Providers organization is possible by electronic means at the following e-mail address: konkt@mojstolik.pl",
  "privacyPolicyPage.point.two.5":
    "We reserve the right to process your data after termination of the Agreement or withdrawal of consent only to the extent necessary to pursue possible claims before the court or if national or EU regulations or international law oblige us to retain data.",
  "privacyPolicyPage.point.two.6":
    "The Service Provider has the right to share the Users personal data and other data with entities authorized under applicable law (e.g. law enforcement authorities).",
  "privacyPolicyPage.point.two.7":
    "Deletion of personal data may occur as a result of withdrawing consent or filing a legally permissible objection to the processing of personal data.",
  "privacyPolicyPage.point.two.8":
    "The Service Provider does not disclose personal data to entities other than those authorized under applicable law.",
  "privacyPolicyPage.point.two.9":
    "Personal data is processed only by persons authorized by us or by processors with whom we closely cooperate.",
  /* -- restaurant profile page -- */
  "restaurantPage.button.pageWWW": "Web WWW",
  "restaurantPage.button.seeMenu": "See menu",
  "restaurantPage.button.confirm": "Confirm",
  "restaurantPage.button.cancel": "Cancel",
  "restaurantPage.button.choose": "Choose",
  "restaurantPage.button.back": "Back",
  "restaurantPage.button.showMore.promotion": "More",
  "restaurantPage.button.showMore": "Show more",
  "restaurantPage.button.showLess": "Show less",
  "restaurantPage.button.goToHomePage": "Back to main page",
  "restaurantPage.button.reservationDetails": "Reservation details",
  "restaurantPage.button.goToPaymentPage": "Go to payment panel",
  "restaurantPage.paymentPage.info":
    "You will be redirected to the payment operator right away Przelewy24.pl",
  "restaurantPage.header.restaurantDescription": "Restaurant description",
  "restaurantPage.header.bookingPolicy": "Booking policy",
  "restaurantPage.header.gallery": "Gallery",
  "restaurantPage.header.yourReservation": "Your reservation",
  "restaurantPage.header.yourPersonalData": "Your personal data",
  "restaurantPage.header.ctnWithoutLogin": "Continue without logging in",
  "restaurantPage.header.login": "Log in",
  "restaurantPage.button.login": "Sign in",
  "restaurantPage.button.orLoginWith": "Or sign in using",
  "restaurantPage.header.createAccount": "Create an account on MojStolik.pl and gain:",
  "restaurantPage.header.createAccount.benefit.1": "Quick booking",
  "restaurantPage.header.createAccount.benefit.2": "Full booking history",
  "restaurantPage.header.createAccount.benefit.3": "Access to unique promotions",
  "restaurantPage.button.createAccount": "Create a MojStolik.pl account",
  "restaurantPage.header.statements": "Statements",
  "restaurantPage.header.promotions": "Special offers and events",
  "restaurantPage.header.use": "Use",
  "restaurantPage.header.cancel": "Cancel",
  "restaurantPage.header.reservationConfirmation": "Reservation Confirmation",
  "restaurantPage.header.reservationsPersons": "Reservations from {{min}} to {{max}} people",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Enter the code from the SMS we sent to your phone number to complete the table reservation process.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Enter the code from the message we sent to your email address to complete the table reservation process.",
  "restaurantPage.input.label.code": "Enter the code from",
  "restaurantPage.input.placeholder.code": "",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "No hours available in these criteria (date, number of person, promotion). Choose a different date, number of person or promotion.",
  "restaurantPage.paragraph.noAvailableHours": "No hours available in these criteria.",
  "restaurantPage.paragraph.noAvailableHoursExt": "Choose a different date or number of person.",
  "restaurantPage.input.label.date": "Date",
  "restaurantPage.input.label.hour": "Hour",
  "restaurantPage.input.label.persons": "Number of person",
  "restaurantPage.input.label.persons.optionExtend": "p.",
  "restaurantPage.input.label.firstName": "First name",
  "restaurantPage.input.label.vatIdNumber": "Vat ID Number",
  "restaurantPage.input.label.address": "Address",
  "restaurantPage.input.label.companyName": "Company Name",
  "restaurantPage.input.label.street": "Street",
  "restaurantPage.input.label.buildingNumber": "Building number",
  "restaurantPage.input.label.localNumber": "Local number",
  "restaurantPage.input.label.postalCode": "Postal code",
  "restaurantPage.input.label.city": "City",
  "restaurantPage.input.label.country": "Country",
  "restaurantPage.get.company.data": "Download",
  "restaurantPage.input.label.lastName": "Last name",
  "restaurantPage.input.label.phoneNumber": "Phone number",
  "restaurantPage.input.label.email": "E-mail address",
  "restaurantPage.input.label.password": "Password",
  "restaurantPage.input.label.passwordRepeat": "Repeat password",
  "restaurantPage.input.label.description": "Comments regarding the reservation",
  "restaurantPage.input.label.footer":
    "MojStolik uses various electronic communication channels and conducts business using e-mail, telephone, SMS and MMS. The administrator of your personal data is Table4Rest limited liability company with its registered office in Warsaw, 02-656 Warsaw, Ksawerów 21, KRS 0000752110, NIP 5213842139. Your personal data will be processed in order to direct marketing content to you in electronic communication channels. More information on the processing of personal data, including your rights, can be found in the Privacy Policy.",
  "agreements.allAgreements": "Check all",
  "agreements.invoice": "Provide the invoice details",
  "restaurantPage.input.label.personalData":
    "I declare that I am aware of and accept the provisions of the Terms and Conditions for the provision of services by MojStolik via the Customer Application. ",
  "restaurantPage.input.label.personalData.extension": "Regulamin MojStolik",
  "restaurantPage.input.label.readWithdrawal":
    "[EN] Zapoznałem się z pouczeniem o prawie odstąpienia od",
  "restaurantPage.input.label.readWithdrawal.extension": "agreement with the customer mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information about MojStolik services and products available on the Customer Application, via electronic communication. You may withdraw this consent at any time.",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information to me about the services and products of the Restaurant and Cooperating Entities indicated on mojstolik.co.uk, available in the Customer Application, via electronic communication. You may withdraw this consent at any time.",
  "restaurantPage.input.label.marketingAgreement3thPart.extension": "at this link",
  "agreement.eventAgreement": "I have read and accept the terms and conditions of special offers",
  "restaurantPage.input.placeholder.typeIn": "Type in",
  "restaurantPage.input.placeholder.firstName": "E.g. George",
  "restaurantPage.input.placeholder.lastName": "E.g. Jones",
  "restaurantPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "restaurantPage.input.placeholder.email": "E.g. address@email.com",
  "restaurantPage.paragraph.fillOrLogin":
    "Fill out the form or download personal data from Facebook, Google or Apple",
  "restaurantPage.paragraph.doYouWantToRegister":
    "If you would like to create a MOJSTOLIK.PL account please set a password",
  "restaurantPage.paragraph.dataIsIncorrect": "The data is incorrect?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "The code has been sent again via SMS. Didn't receive the code?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Authorize yourself via e-mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Send the code to the provided e-mail address.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "The code has been sent to the e-mail address:",
  "restaurantPage.paragraph.emailCommunicationAlert": "WARNING: Further communications regarding your reservation will be sent to the provided email address.",
  "restaurantPage.anchor.sendCodeAgain": "Resend code",
  "restaurantPage.anchor.edit": "Edit",
  "restaurantPage.anchor.login": "Login",
  "restaurantPage.paragraph.or": " or ",
  "restaurantPage.anchor.register": "register",
  "restaurantPage.paragraph.dontWontUsePromotion": "I don't want to use the promotion",
  "restaurantPage.messages.codeResent": "The SMS code has been resent.",
  "restaurantPage.header.reservationRecieved": "Reservation recieved",
  "restaurantPage.summary.paragraph.firstLastName": "Name Surname:",
  "restaurantPage.summary.paragraph.phoneNumber": "Phone number:",
  "restaurantPage.summary.paragraph.email": "E-mail address:",
  "restaurantPage.summary.paragraph.restName": "Restaurant name:",
  "restaurantPage.summary.paragraph.date": "Date:",
  "restaurantPage.summary.paragraph.date.time": ", at",
  "restaurantPage.summary.paragraph.duration": "Duration:",
  "restaurantPage.summary.paragraph.persons": "Guests:",
  "restaurantPage.summary.paragraph.totalCost": "Prepayments:",
  "restaurantPage.summary.special.offer": "Special offer:",
  "restaurantPage.summary.paragraph.state": "Status:",
  "restaurantPage.paragraph.tableReserved": "A table was reserved",
  "reservation.status.Canceled": "Rejected",
  "reservation.status.Completed": "Booking completed",
  "reservation.status.Confirmed": "The reservation has been confirmed",
  "reservation.status.Delayed": "Delayed",
  "reservation.status.NotCompleted": "Unrealized booking",
  "reservation.status.Rejected": "The reservation was canceled",
  "reservation.status.WaitingForApproval": "The reservation is awaiting for approval",
  "reservation.status.WaitingForConfirmation": "Awaiting",
  "restaurantPage.paragraph.tableReservedDescription": "Your table has been reserved at",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "on",
  "restaurantPage.paragraph.tableWaitingForApproval": "Your reservation is waiting for approval",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1": "Your reservation at",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "If you will not receive confirmation in the next few hours please contact the Restaurant directly",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "on",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "After successful approval you will get sms notification with payment link. Your reservation will be valid after payment confirmation",
  "restaurantPage.paragraph.tableReservedPaymentInformation": "Reservation needs to be payed",
  /* -- for guests page -- */
  "forGuestPage.header": "For visitor",
  "forGuestPage.subheader": "",
  /* -- how it works page -- */
  "howItWorksPage.header": "How it works?",
  "howItWorksPage.subheader": "Step by step",
  "howItWorksPage.content.title": "Reservation from the mobile application",
  "howItWorksPage.content.step1": "Step 1",
  "howItWorksPage.content.step1.content":
    "The customer in the mobile application searches for restaurants and ",
  "howItWorksPage.content.step1.content.strong": "sees available tables in real time.",
  "howItWorksPage.content.step2": "Step 2",
  "howItWorksPage.content.step2.content.strong":
    "The manager sees the booking request in his panel",
  "howItWorksPage.content.step2.content":
    " He knows if the request came from a new customer or a regular one and if there are any table preferences.",
  "howItWorksPage.content.step3": "Krok 3",
  "howItWorksPage.content.step3.content": "The customer comes to the restaurant where ",
  "howItWorksPage.content.step3.content.strong":
    "is greeted by name. The service has full customer details",
  "howItWorksPage.content.step3.contentb":
    " — knows if he is their regular guest or if he is visiting them for the first time.",
  "howItWorksPage.phone.title": "Telephone booking",
  "howItWorksPage.content.phone.step1": "Step 1",
  "howItWorksPage.content.phone.step1.content1": "Client ",
  "howItWorksPage.content.phone.step1.content.strong": "calls the selected restaurant ",
  "howItWorksPage.content.phone.step1.content2": "with a booking request.",
  "howItWorksPage.content.phone.step2": "Step 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "The manager in his panel sees the availability of tables in real time",
  "howItWorksPage.content.phone.step2.content":
    ". By entering the customers phone number, the manager immediately sees his data or completes / enters the data of a new customer.",
  "howItWorksPage.content.phone.step3": "Step 3",
  "howItWorksPage.content.phone.step3.content": "The customer comes to the restaurant where ",
  "howItWorksPage.content.phone.step3.content.strong":
    "the staff greets him by name, he has full customer details",
  "howItWorksPage.content.phone.step3.contentb":
    " — knows if he is their regular guest or if he is visiting them for the first time.",
  /* -- price list page -- */
  "priceListPage.header": "Price list",
  "priceListPage.subheader": "",
  "priceListPage.content.link.1": "Please contact us for pricing information ",
  "priceListPage.content.link.2": "contact",
  /* -- contact page -- */
  "contactPage.header": "Contact",
  "contactPage.subheader": "Write to us !",
  "contactPage.content.title": "Contact details",
  /* -- documents page -- */
  "documentsPage.header": "Documents",
  "documentsPage.subheader": "Important information",
  "documentsPage.header.for.clients": "For client",
  "documentsPage.header.for.restaurants": "For Restaurants",
  "documentsPage.for.clients.document.1":
    "Regulations for the provision of services by MojStolik via the Application for the Customer",
  "documentsPage.for.clients.document.2": "Information on the processing of personal data",
  "documentsPage.header.for.restaurants.document.1":
    "Regulations for the provision of the service of sharing the MojStolik Application for Restaurants",
  "documentsPage.header.for.restaurants.document.2":
    "Agreement for entrusting the processing of personal data to the Restaurant",
  "documentsPage.header.for.restaurants.document.3": "Personal data of mojstolik.pl",
  /* -- about page -- */
  "aboutPage.header": "About",
  "aboutPage.subheader": "",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " - is a Polish system created by a group of friends - mysterious lovers of good food and the art of hosting in restaurants. It is a technological solution that helps to build a community around places attractive through good food and respect for the time and habits of its loyal guests. MojStolik.pl is a growing group of restaurants that jointly do everything to make Polish gastronomy synonymous with excellent quality, attention to detail and respect for fans of spending time at the table with friends, family or business partners. The founders of the system translated their restaurant passion into the language of technology and thanks to this a gastronomic portal and application were created that connect people at the tables of the most valued and popular restaurants in Poland.",
  "aboutPage.application.header1":
    "Want to book a table in a restaurant? Now you can do it using the application on your phone or from a portal that brings together many restaurants",
  "aboutPage.application.description1":
    "Mojstolik.pl is a new comprehensive booking platform that has recently been available for Warsaw restaurants. It allows catering establishments to conveniently manage table reservations in real time and integrates all communication channels with guests: telephone, email, MojStolik mobile application and the so-called. walk-in. Thanks to it, restaurant managers have access to a dynamic view of the room, they decide about the layout of the rooms, the number of available seats and for how long each table can be booked. An additional advantage of the system is the mechanism of SMS confirmations about the reservation with the option of canceling via a link and sending SMS to guests with a reminder about the visit.",
  "aboutPage.application.header2": "MojStolik.pl: a tablet instead of a booking book",
  "aboutPage.application.description2":
    "MojStolik.pl is also a tool supporting restaurants in building customer loyalty. The system allows you to build a guest database, analyze booking history, and the visit counter shows how many times a given person visits the premises. When making a reservation, guests can add comments such as table preferences, favorite dishes or wines. The new platform allows for the secure storage of customer data in accordance with the requirements of the GDPR, which is not met by the paper reservation book used by restaurants.",
  "aboutPage.application.description2b":
    "The application is used by over 100 restaurants throughout Poland, such as: Stary Dom, Restauracja Baczewskich, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE’S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari and more will be added soon.",
  "aboutPage.application.header3": "MojStable – a new table booking application",
  "aboutPage.application.description3":
    "MojStolik is a new mobile application that allows you to book a table in a restaurant quickly and conveniently. The application allows you to instantly check availability and book a table for a given hour, because it communicates with the restaurant in real time. To the reservation, we can add a special request or comment regarding the favorite table in the restaurant, information about allergies or other preferences, and the restaurant can answer us via chat whether a given request is possible to meet. In the MojStolik application, we can also check the current menu of the restaurant, we also have access to special promotions prepared for us by the restaurants.",
  "aboutPage.contact.data": "Contact details",
  "aboutPage.contact.street": "st.",
  /* -- registration page -- */
  "registrationPage.header": "Registration",
  "registrationPage.subheader":
    "As a logged in user you can reserve tables faster and view your booking history.",
  "registrationPage.columns.header.registerStandard": "Register standard",
  "registrationPage.columns.header.registerWith": "Register with",
  "registrationPage.input.label.firstName": "First name",
  "registrationPage.input.placeholder.firstName": "E.g. George",
  "registrationPage.input.label.lastName": "Last name",
  "registrationPage.input.placeholder.lastName": "E.g. Jones",
  "registrationPage.input.label.phoneNumber": "Phone number",
  "registrationPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "registrationPage.input.label.email": "E-mail address",
  "registrationPage.input.placeholder.email": "E.g. address@email.com",
  "registrationPage.paragraph.optionalFields": "Optional fields",
  "registrationPage.input.label.birthDate": "Date of birth",
  "registrationPage.input.placeholder.birthDate": "E.g. 1976-10-25",
  "registrationPage.input.label.postalCode": "Post code",
  "registrationPage.input.placeholder.postalCode": "E.g. 03-123",
  "registrationPage.input.label.city": "City",
  "registrationPage.input.placeholder.city": "E.g. Warsaw",
  "registrationPage.input.label.streetAndLocalNumber": "Street and local number",
  "registrationPage.input.placeholder.streetAndLocalNumber": "E.g. Grójecka 130/105",
  "registrationPage.input.label.password": "Password",
  "registrationPage.input.placeholder.password": "",
  "registrationPage.input.label.passwordRepeat": "Password repeat",
  "registrationPage.input.placeholder.passwordRepeat": "",
  "registrationPage.header.statements": "Statements",
  "registrationPage.input.label.agreementPersonalData":
    "[EN] Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MójStolik.",
  "registrationPage.input.label.agreementPersonalData.extension":
    "agreement with the customer mojstolik.pl",
  "registrationPage.input.label.agreementReadWithdrawal":
    "[EN] Zapoznałem się z pouczeniem o prawie odstąpienia od",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "agreement with the customer mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Marketing agreement",
  "registrationPage.input.label.agreementMarketing.extension": "Agreement",
  "registrationPage.input.label.promotionReadWithdrawal.extension": "Promotion Agreement",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "[EN] Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "at this link",

  "registrationPage.button.register": "Register",
  "registrationPage.message.sendCodeAgain": "The SMS code has been resent.",
  "registrationPage.message.accountCreated": "Account has been created.",
  "registrationPage.message.registrationTimeExpired": "Registration time has expired",
  "registrationPage.message.enterCodeFromSMS": "Enter the code from the SMS.",
  "registrationPage.paragraph.timeIsOver": "The time is over.",
  "registrationPage.paragraph.timeLeft": "Left",
  "registrationPage.paragraph.seconds": "second(-s)",
  "registrationPage.header.SMSConfirmation": "SMS confirmation",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Enter the SMS code we sent to your phone number to complete registration.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Enter the SMS code from",
  "registrationPage.input.placeholder.code": "Type in code",
  "registrationPage.paragraph.sendCodeAgain": "The SMS code has been resent.",
  "registrationPage.button.sendCodeAgain": "Resend code",
  "registrationPage.button.confirm": "Confirm",
  /* -- not found (404) page -- */
  "notFoundPage.header": "Error 404",
  "notFoundPage.subheader": "Requested URL",
  "notFoundPage.subheaderExtend": "was not found on the server.",
  "notFoundPage.subheaderExtend2": "Try to check if the address provided is correct or go to",
  "notFoundPage.subheaderExtend3": "main page",
  "notFoundPage.button.goToHomePage": "Go to main page",
  /* -- paymentInfo --*/
  "global.toast.payment.count": "Required products have not been selected",
  "paymentInfo.description.label": "Description",
  "paymentInfo.amount.label": "Price",
  "paymentInfo.quantity.label": "Quantity",
  "paymentInfo.total.label": "Total",
  "paymentInfo.nocalculation": "Payments not required",
  "paymentInfo.summarycost.label": "Summary:",
  "paymentInfo.required.products": "Mandatory products",
  "paymentInfo.optional.products": "Optional products",
  "paymentInfo.recalculate": "Recalculate",
  "paymentInfo.amount.to.paid": "The total amount to be paid ",
  "paymentInfo.info":
    "The total number of mandatory products must be equal to the number of people in the reservation.",
  "paymentInfo.info1": "You have to choose more",
  "paymentInfo.info3": "product",
  "paymentInfo.info4": "products",
  "paymentInfo.package.title": "Basic package",
  "paymentInfo.select.product": "Select a product",
  "paymentInfo.select.basic.product": "Choose a basic product",
  "paymentInfo.select.required.product": "Select mandatory products",
  "paymentInfo.too.much.products": "You cannot select more products",
  "restaurantPage.button.confirmAndPay": "Confirm And Pay",
  "restaurantPage.button.closestTime": "Nearest available",
  "component.header.menu.event": "Event - ",
  "paymentInfo.title": "Payment",
  "reservation.payment.status.Created": "Created",
  "reservation.payment.status.WaitingForPayment": "Waiting for payment",
  "reservation.payment.status.ProcessingPayment": "Processing",
  "reservation.payment.status.Completed": "Completed",
  "reservation.payment.status.Cancelled": "Cancelled",
  "reservation.payment.status.Expired": "Expired",
  "reservation.payment.status.Unknown": "Unknown status",
  /* -- custom form -- */
  "custom.form.required": "Requied field",
  "custom.button.send": "Send",
  "custom.button.hide": "Hide",
  "custom.button.show": "Show",

  /* -- reservation confirmation -- */
  "reservation.confirmation.payment.count": "Recalculate products",
  "reservation.confirmation.selected.promotion": "Selected promotion",

  /* -- forRestaurantsPage form -- */
  "forRestaurantsPage.form.toast.success": "Your contact details have been sent.",
  "forRestaurantsPage.form.toast.error": "Error occured. Try again.",
  "forRestaurantsPage.form.email.error": "Invalid email",
  "forRestaurantsPage.label.name": "FIRST NAME AND LAST NAME:",
  "forRestaurantsPage.label.restaurant": "RESTAURANT:",
  "forRestaurantsPage.label.email": "E-MAIL:",
  "forRestaurantsPage.label.phone": "PHONE:",
  "forRestaurantsPage.header.agreement": "STATEMENT",
  "forRestaurantsPage.label.agreement":
    "I consent to the processing of my personal data by Table4Rest sp. z o. o. in order to conclude a contract for the provision of services for sharing and using the MojStolik.pl application and to send the offer electronically.",

  /* -- Survey Page -- */
  "surveyPage.header.title": "Customer survey",
  "surveyPage.header.description":
    "Your feedback is very important to us. Please share your feedback so we can continue to improve our services:",
  "surveyPage.button.complete": "Complete",
  "surveyPage.button.back": "Back",
  "surveyPage.button.next": "Next",
  "surveyPage.surveyCompleteMessage": "Thank you for completing the survey!",
  "surveyPage.emptySurveyMessage":
    "An error occured while downloading survey data. Please try again later.",
  "surveyPage.error": "An error occured.",
  "surveyPage.results.sending": "Your answer is being submitted...",
  "surveyPage.results.success": "Your answers have been submitted",
  "surveyPage.filledAlert.header": "This survey has already been completed"
};

export default frontendTranslationsEN;

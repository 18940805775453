import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initial: {
    name: "",
    lastName: "",
    email: "",
  },
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterData(state, action) {
      state.initial = {
        name: action.payload.name,
        lastName: action.payload.lastName,
        email: action.payload.email,
        login: action.payload.login,
        accessToken: action.payload.accessToken,
        storageKey: action.payload.storageKey
      };
    },
  },
});

export const registerActions = registerSlice.actions;
export default registerSlice.reducer;
